import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";

export const StepperContext = createContext(null);

export const StepperProvider = ({ children, steps }) => {
  const [activeSteps, setActiveSteps] = useState(() => steps);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const value = useMemo(
    () => ({
      steps: activeSteps,
      updateSteps: setActiveSteps,
      activeStepIndex,
      setActiveStepIndex,
    }),
    [activeSteps, activeStepIndex]
  );

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.node,
  steps: PropTypes.array,
  numberOfSteps: PropTypes.number,
};

export const useStepper = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }

  return context;
};

export const useStepperNavigation = () => {
  const { steps, activeStepIndex, setActiveStepIndex, updateSteps } =
    useStepper();

  const activeStepItem = useMemo(
    () => steps?.[activeStepIndex] ?? null,
    [activeStepIndex, steps]
  );
  const numberOfSteps = useMemo(() => steps?.length ?? 0, [steps?.length]);

  const onStep = useCallback(
    targetIndex => {
      if (targetIndex >= numberOfSteps || targetIndex < 0) {
        return;
      }

      setActiveStepIndex(targetIndex);
    },
    [numberOfSteps, setActiveStepIndex]
  );

  const onNextStep = useCallback(() => {
    const nextIndex = activeStepIndex + 1;

    onStep(nextIndex);
  }, [activeStepIndex, onStep]);

  const onPrevStep = useCallback(() => {
    const prevIndex = activeStepIndex - 1;

    onStep(prevIndex);
  }, [activeStepIndex, onStep]);

  const appendStep = useCallback(
    nextStepItem => {
      if (steps.findIndex(({ url }) => url === nextStepItem.url) !== -1) {
        return;
      }

      updateSteps(prevValue => {
        const nextValue = [...prevValue];
        nextValue.push(nextStepItem);

        return nextValue;
      });
    },
    [steps, updateSteps]
  );

  return {
    steps,
    activeStepItem,
    activeStepIndex,
    onStep,
    onNextStep,
    onPrevStep,
    appendStep,
  };
};
