import React from "react";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { Text } from "@components/Text";
import CardV2 from "@components/CardV2";
import Container from "@components/Container";
import { H4 } from "@components/Heading";
import {LinkArrow} from "../../../components/Link";
import Icon from "@components/Icon";
import Proptypes from "prop-types";
import Routes from "../../../shared/Routes";
import { useUser } from "@contexts/AuthProvider";

const AdditionalInfo = ({customerFormId}) => {
  const { messages } = useIntl();
  const { isCustomer } = useUser();

  return (
    <CardV2 bg="var(--blue-dark)">
      <Container width={1} minWidth={["0px", "610px"]}>
          <Div display="flex" justifyContent="space-between" alignItems="center">
            <Div>
              <H4 display="block" mt={0} mb={2} color="var(--white)">
                {messages.additional_information}
              </H4>
              <Text color="var(--white)">
                {messages.additional_information_para}
              </Text>
            </Div>

            <Div display="flex" alignSelf="center">
              <LinkArrow
                label={messages.fill_the_form}
                direction="left"
                variant="secondary"
                color="var(--white)"
                hoverColor="var(--yellow)"
                //onClick={handleNavigation}
                href={isCustomer ?
                      Routes.ADDITIONAL_INFORMATION_FORM.URL.replace(":order_id", customerFormId) : 
                      Routes.ADMIN_ADDITIONAL_INFORMATION_FORM.URL.replace(":order_id", customerFormId)
                    }
              >
                <Icon ml={1} name="headerarrowright" />
              </LinkArrow>
            </Div>
          </Div>
      </Container>
    </CardV2>
  );
};

AdditionalInfo.propTypes = {
  customerFormId: Proptypes.number
};

export default AdditionalInfo;
