import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import DataTable from "@components/DataTableV2/DataTable";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";
import { ServiceTypeEnum } from "@src/enum/serviceTypeEnum";

import { PRODUCTS, ROUTES } from "@utils/constant";
import { COUPON_STATUS, ORDER_TYPE, PRODUCT_TYPE, SERVICE_TYPE } from "@utils/enum";

import CreateServiceDialog from "./CreateServiceDialog";

const SEARCHABLE_COLUMNS = [
  "id",
  "name",
  "service_type",
  "default_available",
  "listing_order",
  "price",
  "type",
];

const FIELDS = SEARCHABLE_COLUMNS.concat(["description", "is_case"]);

const ServicesList = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);

  const isProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  const handleEditProduct = event => {
    const { id } = event;
    return ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.URL.replace(":id", id);
  };

  const handleOpenCreateProductDialog = () => {
    setShowAddProductDialog(true);
  };

  const handleCloseCreateProductDialog = () => {
    setShowAddProductDialog(false);
  };

  const orderTypeFilterOptions = useMemo(() => Object.values(ORDER_TYPE).map((value) => ({
    name: messages[value],
    code: value,
  })), [messages])

  const config = {
    header: {
      title: "label_services",
      actions: [
        {
          id: "new-service",
          type: "button",
          label: messages.button_add_new,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !isProductsPermission,
          onClick: handleOpenCreateProductDialog,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_availability,
        id: "filter[default_available]",
        type: "checkbox",
        options: [
          {
            label: messages.yes_label,
            value: 1,
            checked: false,
          },
          {
            label: messages.no_label,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_service_type,
        id: "filter[service_type]",
        type: "checkbox",
        options: [
          {
            label: messages.analysis,
            value: SERVICE_TYPE.ANALYSIS,
            checked: false,
          },
          {
            label: messages.assignment,
            value: SERVICE_TYPE.ASSIGNMENT,
            checked: false,
          },
          {
            label: messages.servicetype_bundle,
            value: SERVICE_TYPE.BUNDLE,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[state]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: COUPON_STATUS.ACTIVE,
            checked: true,
          },
          {
            label: messages.state_in_active,
            value: COUPON_STATUS.INACTIVE,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_order_type,
        id: "filter[order_type]",
        type: "multiselect",
        options: [
          {
            id: "filter[order_type]",
            name: "filter[order_type]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: orderTypeFilterOptions,
          }
        ]
      }
    ],
    enable_csv_download: true,
    csv_filename: "services.csv",
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${PRODUCTS}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [
        ["fields", FIELDS],
        ["filter[type]", PRODUCT_TYPE.SERVICE],
      ],
    },
    default_sort: {
      field: "listing_order",
      order: "asc",
    },
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "id",
        sortable: true,
        width: "70px",
      },
      {
        db_field: "name",
        title: "label_service_name",
        type: "service",
        sortable: true,
      },
      {
        db_field: "default_available",
        title: "header_availability",
        type: "text",
        formatter: value => {
          return value ? messages.yes_label : messages.no_label;
        },
        width: "100px",
        sortable: true,
      },
      {
        db_field: "is_case",
        title: "label_case",
        type: "text",
        formatter: value => {
          return value ? messages.yes_label : messages.no_label;
        },
        width: "100px",
        sortable: true,
      },
      {
        db_field: "service_type",
        title: "label_service_type",
        type: "enum",
        formatter: value => {
          return messages[ServiceTypeEnum[value]];
        },
        sortable: true,
        width: "140px",
      },

      {
        db_field: "listing_order",
        title: "label_listing_order",
        type: "text",
        sortable: true,
        width: "140px",
      },
      {
        db_field: "price",
        title: "watchlist_label_price",
        type: "currency",
        sortable: true,
        width: "100px",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: handleEditProduct,
          },
        ],
      },
    ],
  };

  return (
    <>
      {showAddProductDialog && (
        <CreateServiceDialog onClose={handleCloseCreateProductDialog} />
      )}

      <DataTable config={config} />
    </>
  );
};
export default ServicesList;
