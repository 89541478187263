import React from "react";
import PropTypes from "prop-types";

import Card from "@components/Card";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { Text, TextMediumWeight } from "@components/Text";

const SearchOptionCard = ({ iconName, title, description, label, url }) => {
  return (
    <Card
      px={3}
      py="24px"
      borderRadius="10px"
      maxWidth={["100%", "100%", "32%"]}
      width={1}
    >
      <Div
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gridGap={3}
        height={["fit-content", "fit-content", "162px"]}
      >
        <Div display="flex" flexDirection="column" gridGap={2}>
          <Div display="flex" gridGap={1}>
            <Icon
              name={iconName}
              color="var(--turquoise)"
              fontSize="var(--fs-icon-m)"
            />

            <TextMediumWeight>{title}</TextMediumWeight>
          </Div>

          <Text>{description}</Text>
        </Div>

        <LinkArrow
          direction="right"
          href={url}
          label={label}
          variant="secondary"
          fontWeight="var(--medium-weight)"
          flexWrap="nowrap"
          whiteSpace="nowrap"
        >
          <Icon ml={1} name="headerarrowright" />
        </LinkArrow>
      </Div>
    </Card>
  );
};

SearchOptionCard.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  label: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  url: PropTypes.string.isRequired,
};

export default SearchOptionCard;
