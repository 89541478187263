import { useState } from "react";
import { useIntl } from "react-intl";

import createInvoice from "@app/services/billingAccounts/createInvoice";
import getTentativeDetails from "@app/services/billingAccounts/getTentativeDetails";
import patchOrderInvoiceStatus from "@app/services/billingAccounts/patchOrderInvoiceStatus";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  NOT_INVOICED,
  INVOICED,
} from "@utils/constant";
import { INVOICE_MODAL_TYPES, ORDERS_TYPE } from "@utils/enum";
import { getFormattedNumber } from "@utils/utils";

import {
  searchableColumns,
  FILTER_KEY_FROM,
  FILTER_KEY_TO,
  TYPE_DEPENDANT_INVOICE_DETAILS,
} from "./constants";

const useUnbilledAccounts = () => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [invoiceDialogType, setInvoiceDialogType] = useState(
    INVOICE_MODAL_TYPES.CONFIRM
  );
  const [selectedData, setSelectedData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tentativeDetails, setTentativeDetails] = useState(null);
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState(
    ORDERS_TYPE.INDIVIDUAL_SSN
  );

  const hasCustomersPermission = hasAnyPermissions([
    AdminPermissions.AdminReadCustomers,
    AdminPermissions.AdminWriteCustomers,
  ]);
  const hasWriteBillingPermission = hasAnyPermissions([
    AdminPermissions.AdminWriteBilling,
  ]);

  const handleOnFiltersApply = values => {
    setFilters(values);
  };

  const buildPayload = ({ data = [], orders_type = "" }) => {
    const billing_accounts = data.map(({ id = "" }) => id);

    const payload = { billing_accounts, orders_type: orders_type };

    filters.forEach(filter => {
      const { id, values } = filter;
      const [date = ""] = values;

      if (id === FILTER_KEY_FROM) {
        payload.start_date = date;
      }

      if (id === FILTER_KEY_TO) {
        payload.end_date = date;
      }
    });

    return payload;
  };

  const getInvoiceDetails = async data => {
    try {
      setLoading(false);

      const payload = buildPayload(data);
      const response = await getTentativeDetails(payload);

      setTentativeDetails(response);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    } finally {
      setLoading(false);
    }
  };

  const setDataForInvoiceDetails = (data, type) => {
    setInvoiceDialogType(type);
    setSelectedData(data);

    const payload = {
      data,
      orders_type: orderTypeFilterValue,
    };

    getInvoiceDetails(payload);
  };

  const updateInvoiceStatus = async (data, new_status, message) => {
    try {
      setTentativeDetails(null);
      setLoading(true);

      const payload = buildPayload({ data, orders_type: orderTypeFilterValue });

      if (new_status === INVOICED) {
        await createInvoice(payload);
      } else {
        payload.existing_status = NOT_INVOICED;
        payload.new_status = new_status;

        await patchOrderInvoiceStatus(payload);
      }

      showSuccessToast(message);
    } catch (e) {
      showErrorToast(messages.error_try_later);
    } finally {
      setLoading(false);
    }
  };

  const handleInvoiceSelectedAccounts = (_, selectedRows = []) => {
    setDataForInvoiceDetails(selectedRows, INVOICE_MODAL_TYPES.CONFIRM);
  };

  const handleDoNotInvoiceSelectedAccounts = (_, selectedRows = []) => {
    setDataForInvoiceDetails(selectedRows, INVOICE_MODAL_TYPES.CANCEL);
  };

  const handleConfirmInvoiceForAccount = selectedRowData => {
    setDataForInvoiceDetails([selectedRowData], INVOICE_MODAL_TYPES.CONFIRM);
  };

  const handleCancelInvoiceForAccount = selectedRowData => {
    setDataForInvoiceDetails([selectedRowData], INVOICE_MODAL_TYPES.CANCEL);
  };

  const handleCloseConfirmDialog = () => {
    setTentativeDetails(null);
  };

  const handleConfirmInvoice = () => {
    const { nextStatus, messagePlural, messageSingular } =
      TYPE_DEPENDANT_INVOICE_DETAILS[invoiceDialogType];
    const message = selectedData > 1 ? messagePlural : messageSingular;

    updateInvoiceStatus(selectedData, nextStatus, message);
  };

  const handleActiveIndexChange = nextIndex => {
    const nextValue =
      nextIndex === 0 ? ORDERS_TYPE.INDIVIDUAL_SSN : ORDERS_TYPE.SUBSCRIPTION;

    setOrderTypeFilterValue(nextValue);
  };

  const formatOrdersCount = orders => {
    const [firstOrder] = orders ?? [];
    const ordersValue = `${firstOrder?.["ordersCount"]}`;

    return orders ? ordersValue : "-";
  };

  const formatOrdersTotalAmount = orders => {
    const [firstOrder] = orders ?? [];
    const amountValue = `${getFormattedNumber(firstOrder?.["ordersTotal"])} kr`;

    return orders ? amountValue : "-";
  };

  const dataTableConfig = {
    header: {
      title: "label_not_invoiced",
      actions: [
        {
          type: "button",
          label: messages.invoice_all_selected,
          icon: "mark",
          id: "invoice-billing",
          style: { marginRight: "30px" },
          onClick: handleInvoiceSelectedAccounts,
          verifyBtnIsHidden: data => !data?.length,
          formatter: (label, data = []) => `${label} (${data?.length})`,
        },
        {
          type: "button",
          label: messages.do_not_invoice_selected,
          icon: "rubber",
          id: "invoice-billing",
          onClick: handleDoNotInvoiceSelectedAccounts,
          verifyBtnIsHidden: data => !data?.length,
          formatter: (label, data = []) => `${label} (${data?.length})`,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "unbilled_accounts.csv",
    enable_row_selection: hasWriteBillingPermission,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_billing_unbilled_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", "orders,customer"],
        ["filter[orders.invoice_status]", "Not invoiced"],
        ["filter[has_orders]", true],
        ["filter[orders.type]", orderTypeFilterValue],
        [
          "fields",
          [
            "id",
            "name",
            "invoice_remark",
            "active",
            "customer_id",
            "fortnox_customer_id",
            "your_reference",
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "desc",
    },
    columns: [
      {
        title: "label_fortnox_id",
        db_field: "fortnox_customer_id",
        type: "id",
        sortable: true,
        width: "115px",
      },
      {
        title: "label_customer",
        db_field: "customer.customer_name",
        type: "link",
        sortable: true,
        className: hasCustomersPermission ? "text-blue" : "text-grey",
        href: values =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${values["customer_id"]}/${ADMIN_CUSTOMER_DETAILS}`,
      },
      {
        title: "account",
        db_field: "name",
        type: "text",
        sortable: true,
      },

      {
        title: "placeholder_your_reference_person",
        db_field: "your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
        sortable: true,
      },
      {
        title: "billing_orders",
        db_field: "orders",
        formatter: formatOrdersCount,
        type: "text",
        width: "90px",
        sortable: true,
        sortField: "orders.ordersCount",
      },
      {
        title: "amount",
        db_field: "orders",
        type: "text",
        formatter: formatOrdersTotalAmount,
        width: "120px",
        sortable: true,
        sortField: "orders.ordersTotal",
      },

      {
        type: "actions",
        width: "50px",
        isHidden: !hasWriteBillingPermission,
        actions: [
          {
            label: messages.confirm_invoice,
            icon: "icon-mark",
            type: "button",
            onClick: handleConfirmInvoiceForAccount,
          },
          {
            label: messages.do_not_invoice,
            icon: "icon-close",
            type: "button",
            onClick: handleCancelInvoiceForAccount,
          },
        ],
      },
    ],
    onFiltersApply: handleOnFiltersApply,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[orders.created_at][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.label_not_invoiced,
  };

  return {
    dataTableConfig,
    handleActiveIndexChange,
    handleCloseConfirmDialog,
    handleConfirmInvoice,
    headerConfig,
    invoiceDialogType,
    loading,
    tentativeDetails,
  };
};

export default useUnbilledAccounts;
