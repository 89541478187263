import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
  PrimaryButton,
  PrimaryButtonOutlined,
  PrimaryButtonIcon,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import Card from "@components/Card";
import Div from "@components/Div";
import { H1, H4 } from "@components/Heading";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { SurveyForm } from "@components/Survey";

import { useUser } from "@contexts/AuthProvider";

import { BACKGROUND_SE, ROUTES } from "@utils/constant";

import { CUSTOMER_FORM_CONTENT_BY_TYPE } from "./CustomerForm.constants";
import { useCustomerForm } from "./CustomerForm.hooks";

const CustomerForm = ({ type }) => {
  const {
    config,
    formData,
    isLoading,
    isSubmitted,
    personDetails,
    surveyMode,
    handleLoadSurveyRef,
    handleNewSearch,
    handleSave,
    handleSkip,
  } = useCustomerForm();
  const { messages } = useIntl();
  const { isCustomer } = useUser();

  const title = useMemo(
    () =>
      isSubmitted
        ? messages.title_check_form
        : messages[CUSTOMER_FORM_CONTENT_BY_TYPE[type].titleKey],
    [isSubmitted, messages, type]
  );

  return (
    <Div display="flex" flexDirection="column" width={1} gridGap={4}>
      <Div display="flex" flexDirection="column" width={1} gridGap={3}>
        <H1>{title}</H1>

        <H4 display="block">{messages.description_additional_information}</H4>

        <LinkArrow
          href={BACKGROUND_SE + ROUTES.CONTACT_US_ID.URL}
          label={messages.contact_us}
          mt="10px"
          direction="left"
          variant="secondary"
        >
          <Icon ml={1} name="arrowright" color="var(--blue-dark) !important" />
        </LinkArrow>
      </Div>

      <Card p={3} width={1}>
        <Div display="flex" flexDirection="column" gridGap={4}>
          {personDetails !== "" && (
            <H1 textTransform="none" color="var(--blue-dark) !important">
              {personDetails}
            </H1>
          )}

          <SurveyForm
            mode={surveyMode}
            onRefLoaded={handleLoadSurveyRef}
            formConfig={config}
            data={formData}
          />
        </Div>
      </Card>

      <Div
        display="flex"
        flexDirection={["column", "column", "row"]}
        gridGap={[3, 3, 4]}
      >
        {isSubmitted ? (
          <>
            <PrimaryButtonIcon
              onClick={handleNewSearch}
              label={messages["candidate_order_details.new_search"]}
              icon="icon-arrowright"
              iconPos="right"
            />

            {isCustomer && (
              <PrimaryButtonIconOutlined
                onClick={handleSkip}
                label={messages["candidate_order_details.my_orders"]}
                icon="icon-arrowright"
                iconPos="right"
              />
            )}
          </>
        ) : (
          <>
            <PrimaryButton
              disabled={isLoading}
              label={
                messages[
                  CUSTOMER_FORM_CONTENT_BY_TYPE[type].submitButtonLabelKey
                ]
              }
              onClick={handleSave}
            />

            <PrimaryButtonOutlined
              label={messages.label_skip_send_later}
              onClick={handleSkip}
            />
          </>
        )}
      </Div>
    </Div>
  );
};

CustomerForm.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CustomerForm;
