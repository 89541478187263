import * as Yup from "yup";

import Email from "@pages/auth/schema/Email";

const SearchResultsSchema = Yup.object({
  consentCheck: Yup.boolean(),
  candidates: Yup.array().of(
    Yup.object({
      ...Email.fields,
    })
  ),
});

export default SearchResultsSchema;
