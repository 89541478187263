import React, { memo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import FullSizeDialog from "@components/FullSizeDialog";

import { ROUTES } from "@utils/constant";

import CustomerForm, {
  CUSTOMER_FORM_TYPES,
} from "../../shared/CreateOrderWizard/components/CustomerForm";

const Details = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const handleCloseResultsDialog = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(ROUTES.ALLANALYSIS_LIST.URL);
    }
  };

  return (
    <FullSizeDialog
      title={messages.additional_information_form}
      onClose={handleCloseResultsDialog}
    >
      <CustomerForm type={CUSTOMER_FORM_TYPES.ADDITIONAL_INFO} />
    </FullSizeDialog>
  );
};

export default memo(Details);
