import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";

import customerService from "@app/services/customers/customerService";
import userService from "@app/services/users/userService";

import { PrimaryButtonIcon } from "@components/Button";
import Card from "@components/Card";
import Div from "@components/Div";
import Divider from "@components/Divider";
import ErrorDialog from "@components/ErrorDialog";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import { InputAutocomplete, InputDropdown } from "@components/Input";
import { Text } from "@components/Text";

import { useOrder } from "@contexts/OrderContext";
import { useStepperNavigation } from "@contexts/StepperContext";

import ValidationSchema from "./ChooseCustomer.schema";

const ChooseCustomer = () => {
  const { messages } = useIntl();
  const { search } = useLocation();
  const { setOrderUser } = useOrder();
  const { onNextStep } = useStepperNavigation();

  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const isInitialCustomerSet = useRef(false);

  const handleSubmitForm = values => {
    const { customer: { subscription, ...customer } = {}, user = {} } = values;

    const selectedUserInfo = {
      ...user,
      customer: { ...customer },
    };

    if (subscription) {
      const { status: subscriptionStatus = null, available_credits } =
        subscription;

      selectedUserInfo.customer = {
        ...customer,
        status: subscriptionStatus,
        available_credits,
      };
    }

    setOrderUser(selectedUserInfo);

    onNextStep();
  };

  const formik = useFormik({
    initialValues: {
      customer: "",
      user: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: handleSubmitForm,
  });

  const {
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    values,
  } = formik;

  const getCustomers = useCallback(
    async query => {
      try {
        let queryString = "include=subscription";

        if (query) {
          queryString += `&filter[search][columns]=customer_name,id,&filter[search][value]=${query}`;
        }

        const { data: { data: customersData = [] } = {} } =
          await customerService(queryString);
        const formattedData = customersData.map(customer => ({
          ...customer,
          label: `${customer.customer_name} - #${customer.id}`,
        }));

        setCustomers(formattedData);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      }
    },
    [messages.exception_error_message]
  );

  const handleSearch = ({ query = "" }) => {
    getCustomers(query);
  };

  const getUsers = useCallback(
    async customerId => {
      try {
        const queryString = `per_page=1000&filter[customer.id]=${customerId}`;
        const { data: { data: usersData = [] } = {} } =
          await userService(queryString);

        setUsers(usersData);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      }
    },
    [setUsers, messages.exception_error_message]
  );

  const checkCustomerForKlarnaPaymentMethod = customer => {
    if (!customer) {
      return false;
    }

    const { invoice } = customer;
    const { status: subscriptionStatus } = customer.subscription ?? {};

    return !(invoice || subscriptionStatus === "active");
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const handleChangeCustomer = event => {
    const { value: customer } = event;

    const isKlarnaPaymentMethod = checkCustomerForKlarnaPaymentMethod(customer);

    if (isKlarnaPaymentMethod) {
      setFieldValue("customer", "", false);
      setFieldTouched("customer", true, false);
      setFieldError(
        "customer",
        messages.message_cannot_create_order_for_customer
      );

      return;
    }

    handleChange(event);

    if (!customer?.id) {
      return;
    }

    getUsers(customer.id);
  };

  useEffect(() => {
    if (!search || isInitialCustomerSet.current) {
      return;
    }

    const searchParams = new URLSearchParams(search);
    const initialCustomerId = searchParams.get("customerId");

    if (customers.length === 0) {
      getCustomers(initialCustomerId);

      return;
    }

    const initialCustomer = customers.find(({ id }) => id == initialCustomerId);

    if (!initialCustomer) {
      return;
    }

    setFieldValue("customer", initialCustomer);

    getUsers(initialCustomerId);

    isInitialCustomerSet.current = true;
  }, [customers, getCustomers, getUsers, search, setFieldValue]);

  return (
    <Div
      width={[1, 1, "60%", "60%"]}
      display="flex"
      flexDirection="column"
      mt={4}
      gridGap={4}
    >
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <Text>{messages.message_create_order}</Text>

      <Card borderRadius={20} p={3}>
        <H4>{messages.header_select_customer.toUpperCase()}</H4>

        <Div>
          <Divider />

          <InputAutocomplete
            delay={500}
            name="customer"
            optionLabel="label"
            label={messages.label_customer}
            placeholder={messages.placeholder_autocomplete}
            options={customers}
            onComplete={handleSearch}
            onChange={handleChangeCustomer}
            value={values.customer}
            formikProps={formik}
          />

          <InputDropdown
            name="user"
            options={users}
            optionLabel="name"
            label={messages.user_label}
            placeholder={`${messages.placeholder_choose}...`}
            disabled={!values.customer || formik.errors.customer}
            onChange={handleChange}
            value={values.user}
          />
        </Div>
      </Card>

      <PrimaryButtonIcon
        width={[1, 1, "160px", "160px"]}
        label={messages.label_next}
        iconPos="right"
        onClick={handleSubmit}
        disabled={!(formik.isValid && formik.dirty)}
      >
        <Icon ml={1} name="arrowright" />
      </PrimaryButtonIcon>
    </Div>
  );
};

export default ChooseCustomer;
