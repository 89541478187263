import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { useUser } from "@contexts/AuthProvider";
import { useOrder } from "@contexts/OrderContext";

import Stepper from "@pages/shared/Wizard/Stepper";

import { ROUTES } from "@utils/constant";

const OrderStepper = () => {
  const history = useHistory();
  const { isLoading, orderUser } = useOrder();
  const { isAdmin } = useUser();

  const handleClose = () => {
    history.push(ROUTES.SEARCH.URL);
  };

  useEffect(() => {
    if (orderUser) {
      return;
    }

    const targetUrl = isAdmin
      ? ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL
      : ROUTES.WIZARD_SEARCH.URL;

    const searchParams = new URLSearchParams(history.location.search);

    history.push({ pathname: targetUrl, search: searchParams.toString() });
  }, [history, isAdmin, orderUser]);

  return <Stepper isLoading={isLoading} onClose={handleClose} />;
};

OrderStepper.propTypes = {
  steps: PropTypes.array,
};

export default OrderStepper;
