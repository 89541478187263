import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { personInformationActions } from "@app/reducers/personInformationReducer";

import { useStepperNavigation } from "@contexts/StepperContext";

import { formatDate } from "@utils/utils";

export const useCandidateInfo = () => {
  const dispatch = useDispatch();
  const { onNextStep } = useStepperNavigation();

  const handleSubmit = useCallback(
    ({ hasSSN, ...restData }) => {
      const payload = {
        ...restData,
      };

      if (!hasSSN) {
        payload.date_of_birth = formatDate(restData.date_of_birth);
      }

      dispatch(personInformationActions.personInfo(payload));

      onNextStep();
    },
    [dispatch, onNextStep]
  );

  return {
    handleSubmit,
  };
};
