import React, { useEffect, useRef, useState } from "react";
import SurveyForm from "../../../../components/Survey/SurveyForm";
import ContactPersonConfig from "./ContactPersonConfig.json";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";
import PropTypes from "prop-types";

function ContactPersonEditFeildCard({ title, config, onHide, onSubmit }) {
  const surveyRef = useRef(null);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const initialData = {
      firstname:
        config.find(value => value.key === "firstname")?.value || null,
        lastname:
        config.find(value => value.key === "lastname")?.value || null,
      email: config.find(value => value.key === "email")?.value || null,
      phone: config.find(value => value.key === "phone")?.value || null,
    };
    setProductData(initialData);
  }, [config]);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    onSubmit(data);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, 1]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
      className="popup-card"
    >
      <H3 textAlign="center" pt="8px">
        {title}
      </H3>
      <SurveyForm
        data={productData}
        formConfig={ContactPersonConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
        onClose={onHide}
      />
    </Dialog>
  );
}

ContactPersonEditFeildCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ContactPersonEditFeildCard;
