import React from "react";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import Link from "@components/Link";
import { Text } from "@components/Text";

import { PRIVACY_POLICY_URL } from "@utils/constant";

const PrivacyPolicyFooter = () => {
  const { messages } = useIntl();

  return (
    <Div
      width={1}
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      justifyContent={["center", "center", "space-between", "space-between"]}
      gridGap={[3, 3, 0, 0]}
      alignItems="center"
      justifySelf="flex-end"
    >
      <Text fontSize="var(--fs-text-small)">
        {messages.label_responsible_publisher}
      </Text>

      <Link
        label={messages.label_privacy_policy}
        href={PRIVACY_POLICY_URL}
        target="_blank"
        rel="noopener noreferrer"
        color="var(--grey-dark)"
        fontWeight="var(--light-weight)"
        fontSize="var(--fs-text-small)"
      />
    </Div>
  );
};

export default PrivacyPolicyFooter;
