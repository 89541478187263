import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import FullSizeDialog from "@components/FullSizeDialog";
import CustomerForm, {
  CUSTOMER_FORM_TYPES,
} from "@pages/shared/CreateOrderWizard/components/CustomerForm";

const CustomerFormDialog = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { pathname } = useLocation();

  const handleClose = () => {
    history.push({
      pathname,
      search: "",
    });
  };

  return (
    <FullSizeDialog
      title={messages.additional_information_form}
      onClose={handleClose}
    >
      <CustomerForm type={CUSTOMER_FORM_TYPES.ADDITIONAL_INFO} />
    </FullSizeDialog>
  );
};

export default CustomerFormDialog;
