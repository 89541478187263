import React from "react";
import { useIntl } from "react-intl";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import { CasesListTab, EmailLogListTab, ManualDataSourcesTab } from "./tabs";

export const useAdminCasesList = () => {
  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();

  const [title, setTitle] = React.useState();

  const hasEmailLogPermission = hasAllPermissions([
    AdminPermissions.AdminReadEmailLog,
  ]);

  const hasDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminReadDataSources,
  ]);

  const tabContent = React.useMemo(
    () => [
      {
        title: messages.title_cases,
        content: <CasesListTab />,
        url: ROUTES.ADMIN_CASES_LIST.URL,
      },
      {
        title: messages.title_email_log,
        content: <EmailLogListTab />,
        url: ROUTES.ADMIN_EMAIL_LOG.URL,
        isHidden: !hasEmailLogPermission,
      },
      {
        title: messages.title_data_source_manual,
        content: <ManualDataSourcesTab />,
        url: ROUTES.ADMIN_MANUAL_DATA_SOURCES_LIST.URL,
        isHidden: !hasDataSourcesPermission,
      },
    ],
    [
      hasDataSourcesPermission,
      hasEmailLogPermission,
      messages.title_cases,
      messages.title_data_source_manual,
      messages.title_email_log,
    ]
  );

  const headerConfig = React.useMemo(
    () => ({
      title,
    }),
    [title]
  );

  const handleActiveIndexChange = React.useCallback(
    index => {
      const nextTitle = tabContent[index]?.title;

      setTitle(nextTitle);
    },
    [tabContent]
  );

  return {
    headerConfig,
    tabContent,
    handleActiveIndexChange,
  };
};
