import React from "react";
import { useIntl } from "react-intl";

import Card from "@components/CardV2";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Logo from "@components/Logo";
import { TextMediumWeight } from "@components/Text";

import { IndividualWithCoordinationNumberLogo } from "./assets";
import { CoordinationNumberForm } from "./components";
import { useIndividualWithCoordinationNumber } from "./IndividualWithCoordinationNumber.hooks";

const IndividualWithCoordinationNumber = () => {
  const { messages } = useIntl();
  const { handleSubmit } = useIndividualWithCoordinationNumber();

  return (
    <Div display="flex" flexDirection="column" w={1} gridGap={4}>
      <Div
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        gridGap={3}
      >
        <Card display="flex" p={3} borderRadius="10px">
          <Div
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gridGap="1.5rem"
          >
            <H1>{messages.heading_individual_with_coordination_number}</H1>

            <TextMediumWeight color="var(--grey) !important">
              {messages.details_individual_with_coordination_number}
            </TextMediumWeight>
          </Div>
        </Card>

        <Logo
          logo={IndividualWithCoordinationNumberLogo}
          borderRadius="10px"
          width={[1, 1, "50%"]}
          maxWidth={["100%", "100%", "448px"]}
        />
      </Div>

      <Div
        display="flex"
        flexDirection="column"
        gridGap={2}
        width={[1, 1, "58%"]}
      >
        <CoordinationNumberForm onSubmit={handleSubmit} />
      </Div>
    </Div>
  );
};

export default IndividualWithCoordinationNumber;
