import React from "react";
import { useIntl } from "react-intl";

import Card from "@components/Card";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Logo from "@components/Logo";
import { TextMediumWeight } from "@components/Text";

import { IndividualSSNNotAvailableLogo } from "./assets";
import { IndividualSSNNotAvailableForm } from "./components";
import { useIndividualSSNNotAvailable } from "./IndividualSSNNotAvailable.hooks";

const IndividualSSNNotAvailable = () => {
  const { messages } = useIntl();
  const { handleSubmit } = useIndividualSSNNotAvailable();

  return (
    <Div display="flex" flexDirection="column" w={1} gridGap={4}>
      <Div
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        alignItems="center"
        gridGap={3}
      >
        <Div width={1}>
          <Card p={3} borderRadius="10px">
            <Div display="flex" flexDirection="column" gridGap="1.5rem">
              <H1>{messages.heading_individual_ssn_not_available}</H1>

              <TextMediumWeight color="var(--grey) !important">
                {messages.details_individual_with_coordination_number}

                <br />

                {[
                  messages.details_individual_with_coordination_number_option_1,
                  messages.details_individual_with_coordination_number_option_2,
                  messages.details_individual_with_coordination_number_option_3,
                ].map((message, index) => (
                  <Div ml={1} key={index}>
                    {`• ${message}`} <br />
                  </Div>
                ))}
              </TextMediumWeight>
            </Div>
          </Card>
        </Div>

        <Logo
          logo={IndividualSSNNotAvailableLogo}
          borderRadius="10px"
          width={[1, 1, "50%"]}
          maxWidth={["100%", "100%", "448px"]}
        />
      </Div>

      <Div
        display="flex"
        flexDirection="column"
        gridGap={2}
        width={[1, 1, "58%"]}
      >
        <IndividualSSNNotAvailableForm onSubmit={handleSubmit} />
      </Div>
    </Div>
  );
};

export default IndividualSSNNotAvailable;
