import React from "react";

import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";

import { DataEditorDialog } from "@pages/admin/caseDetails/tabs/SourceDataTab/components";

import { useManualDataSourcesTab } from "./ManualDataSourcesTab.hooks";

const ManualDataSourcesTab = () => {
  const { config, isLoading, jsonData, handleCloseDataDialog } =
    useManualDataSourcesTab();

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {jsonData !== null && (
        <DataEditorDialog data={jsonData} onClose={handleCloseDataDialog} />
      )}

      <DataTable config={config} />
    </>
  );
};

export default ManualDataSourcesTab;
