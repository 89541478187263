import React, { useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Card from "@components/Card";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import { SurveyForm } from "@components/Survey";

import config from "./CompanyDetailsForm.config.json";

const CompanyDetailsForm = ({ onSubmit }) => {
  const { messages } = useIntl();

  const surveyRef = useRef(null);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { current } = surveyRef;

    if (!current) {
      return;
    }

    const { data } = current;

    onSubmit(data);
  };

  return (
    <Card p={3}>
      <H4>{messages.title_company_check}</H4>

      <Div my={3} width={1} height={1} bg="var(--grey-lightest)" />

      <SurveyForm
        formConfig={config}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
      />
    </Card>
  );
};

CompanyDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default CompanyDetailsForm;
