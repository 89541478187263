import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import { AssigneeCard, CustomerFormCard } from "./components";
import { useDetailsTab } from "./DetailsTab.hooks";

const DetailsTab = ({ caseDetails, onCaseDetailsUpdate }) => {
  const {
    config,
    isLoading,
    shouldShowAssigneeCard,
    shouldShowCustomerFormCard,
    handleOpenErrorDialog,
    handleUpdateAssignee,
    handleUpdateCase,
  } = useDetailsTab(caseDetails, onCaseDetailsUpdate);
  const { messages } = useIntl();

  return (
    <Div pb={3} display="flex" flexDirection="column">
      {isLoading && <ProgressSpinner />}

      <EditableFieldsCard
        title={messages.title_general_data}
        config={config.generalData}
      />

      {shouldShowAssigneeCard && (
        <AssigneeCard
          selectedAssignee={caseDetails.user}
          onUpdateAssignee={handleUpdateAssignee}
          onError={handleOpenErrorDialog}
        />
      )}

      <EditableFieldsCard
        title={messages.title_statuses}
        config={config.statuses}
        onSubmit={handleUpdateCase}
        isEditPermission={shouldShowAssigneeCard}
      />

      {shouldShowCustomerFormCard && (
        <CustomerFormCard
          id={caseDetails.order_customer_form.id}
          status={caseDetails.order_customer_form.status}
        />
      )}
    </Div>
  );
};

DetailsTab.propTypes = {
  caseDetails: PropTypes.object,
  onCaseDetailsUpdate: PropTypes.func,
};

export default DetailsTab;
