import React, { useMemo } from "react";
import { Text, TextSemiBoldWeight } from "@components/Text";
import Div from "@components/Div";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { addHyphenToNumber } from "@utils/utils";

function Header({
  ssn,
  name,
  isConsent,
  email,
  person_first_name,
  person_last_name,
  date_of_birth,
  company_name,
  organisation_number,
  coordination_number,
}) {
  const { messages } = useIntl();

  const itemsToDisplay = useMemo(() => {
    const candidateName =
      name ?? [person_first_name, person_last_name].filter(Boolean).join(" ");

    const resultItems = [
      { titleKey: "label_candidate_name", value: candidateName },
      { titleKey: "label_personal_number", value: addHyphenToNumber(ssn, 8) },
      { titleKey: "label_date_of_birth", value: date_of_birth },
      { titleKey: "label_company_name", value: company_name },
      { titleKey: "label_organisation_number", value: organisation_number },
      { titleKey: "label_coordination_number", value: coordination_number },
    ];

    if (isConsent) {
      resultItems.push({
        titleKey: "lable_candidate_email",
        value: email,
      });
    }

    return resultItems.filter(({ value }) => Boolean(value));
  }, [
    company_name,
    coordination_number,
    date_of_birth,
    email,
    isConsent,
    name,
    organisation_number,
    person_first_name,
    person_last_name,
    ssn,
  ]);

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      justifyContent="space-between"
      alignItems={["", "", "center", "center"]}
      gridGap={[20, 20, 0, 0]}
    >
      {itemsToDisplay.map(({ titleKey, value }) => (
        <Div
          key={titleKey}
          display="flex"
          flexDirection={["row", "row", "column", "column"]}
          justifyContent="space-between"
          alignItems={["center", "center", "start", "start"]}
        >
          <Text>{messages[titleKey]}</Text>

          <TextSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-text)",
              "var(--fs-text)",
            ]}
            mt={[0, 0, 2, 2]}
          >
            {value}
          </TextSemiBoldWeight>
        </Div>
      ))}
    </Div>
  );
}

Header.propTypes = {
  ssn: PropTypes.number,
  name: PropTypes.string,
  isConsent: PropTypes.bool,
  email: PropTypes.string,
  person_first_name: PropTypes.string,
  person_last_name: PropTypes.string,
  date_of_birth: PropTypes.string,
  company_name: PropTypes.string,
  organisation_number: PropTypes.string,
  coordination_number: PropTypes.string,
};

export default Header;
