import styled from "styled-components";
import { Card as PRCard } from "primereact/card";
import {
  compose,
  space,
  layout,
  flexbox,
  color,
  typography,
  border,
} from "styled-system";

const Card = styled(PRCard)`
  ${compose(layout, space, flexbox, color, typography, border)};
  border: 1px solid var(--grey-lightest);
  border-radius: 10px;
  border-shadow: none;
  min-height: fit-content;

  .p-card-body {
    padding: 0;

    .p-card-title {
      font-size: var(--fs-h5);
      padding-bottom: 14px;
    }
    .p-card-content {
      padding: 0;
    }
    .p-card-footer {
      padding: 0;
    }
  }
`;

export default Card;
