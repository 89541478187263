import React from "react";
import Div from "@components/Div";
import PropTypes from "prop-types";
import Skeleton from "@components/Skeleton";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Card from "@components/Card";
import styled from "styled-components";
import Divider from "@components/Divider";
import { ASSIGNMENT } from "@utils/constant";

const StyledContainer = styled(Container)`
  position: relative;
  box-shadow: none;
`;
const StyledCard = styled(Card)`
  border-radius: 10px;
`;
const OrderConfirmationLoader = props => {
  const { orderItems = [] } = props;
  const groupedData = orderItems.reduce((result, item) => {
    const category = item.personal_number;
    if (!result[category]) {
      result[category] = {
        items: [],
        person_name: item.person_name,
        personal_number: item.personal_number,
        id: item.id,
      };
    }
    result[category].items.push(item);
    return result;
  }, {});

  return (
    <ScrollToTop>
      {Object.keys(groupedData).map(category => {
        const { items } = groupedData[category];
        return (
          <Div key={category}>
            <StyledCard mb={5} p={0} maxWidth="1110px">
              <StyledContainer
                width={1}
                py={"26px"}
                px={[2, 3]}
                maxWidth="1110px"
              >
                <Div
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["column", "row"]}
                >
                  <Skeleton maxWidth="45px" height="20px" borderRadius="10px" />
                  <Skeleton
                    maxWidth="129px"
                    height="20px"
                    borderRadius="10px"
                  />
                </Div>
                <Div
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["column", "row"]}
                  mt={2}
                >
                  <Skeleton
                    maxWidth="144px"
                    height="35px"
                    borderRadius="10px"
                  />
                  <Skeleton
                    maxWidth="129px"
                    height="35px"
                    borderRadius="10px"
                  />
                </Div>
              </StyledContainer>
              <Divider m={"0px !important"}></Divider>

              <Container
                mr={[0, 0, 0, 4]}
                width={1}
                py={"16px"}
                px={[3, 3]}
                maxWidth="1110px"
              >
                <Div display="flex" justifyContent="space-between">
                  <Skeleton
                    maxWidth="60px"
                    height="25px"
                    borderRadius="10px"
                    mt={"10px"}
                  />
                  <Div
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={["column", "row"]}
                    width={["40%", "35%", "30%"]}
                  >
                    <Skeleton
                      maxWidth="90px"
                      height="25px"
                      borderRadius="10px"
                      mt={"10px"}
                    />
                    <Skeleton
                      maxWidth="40px"
                      height="25px"
                      borderRadius="10px"
                      mt={"10px"}
                    />
                  </Div>
                </Div>
              </Container>
              <Divider m={"0px !important"}></Divider>

              {items.map(item => (
                <>
                  {item.service_type === ASSIGNMENT ? (
                    (Number(item.price) > 0 || item.credits > 0) && (
                      <Div key={item.id}>
                        <Container
                          mr={[0, 0, 0, 4]}
                          width={1}
                          py={"26px"}
                          px={[3, 3]}
                          maxWidth="1110px"
                        >
                          <Div
                            display="flex"
                            justifyContent="space-between"
                            flexDirection={["column", "row"]}
                            width={["70%", "60%", "48%"]}
                          >
                            <Skeleton
                              maxWidth="185px"
                              height="25px"
                              borderRadius="10px"
                            />
                            <Skeleton
                              maxWidth="74px"
                              height="25px"
                              borderRadius="10px"
                            />
                            <Skeleton
                              maxWidth="120px"
                              height="25px"
                              borderRadius="10px"
                            />
                          </Div>
                          <Div
                            display="flex"
                            justifyContent="space-between"
                            flexDirection={["column", "row"]}
                            mt={"26px"}
                          >
                            <Div
                              display="flex"
                              justifyContent="space-between"
                              flexDirection={["column", "row"]}
                              width={["70%", "60%", "54%"]}
                            >
                              <Skeleton
                                maxWidth="110px"
                                height="35px"
                                borderRadius="10px"
                              />
                              <Skeleton
                                maxWidth="110px"
                                height="35px"
                                borderRadius="10px"
                              />
                              <Skeleton
                                maxWidth="240px"
                                height="35px"
                                borderRadius="10px"
                              />
                            </Div>
                            <Skeleton
                              maxWidth="120px"
                              height="25px"
                              borderRadius="10px"
                            />
                          </Div>
                        </Container>
                        <Divider m={"0px !important"}></Divider>
                      </Div>
                    )
                  ) : (
                    <Div key={item.id}>
                      <Container
                        mr={[0, 0, 0, 4]}
                        width={1}
                        py={"16px"}
                        px={[3, 3]}
                        maxWidth="1110px"
                      >
                        <Div display="flex" justifyContent="space-between">
                          <Skeleton
                            maxWidth="280px"
                            height="25px"
                            borderRadius="10px"
                            mt={"10px"}
                          />
                          <Div
                            display="flex"
                            justifyContent="space-between"
                            flexDirection={["column", "row"]}
                            width={["40%", "35%", "30%"]}
                          >
                            <Skeleton
                              maxWidth="68px"
                              height="25px"
                              borderRadius="10px"
                              mt={"10px"}
                            />
                            <Skeleton
                              maxWidth="72px"
                              height="25px"
                              borderRadius="10px"
                              mt={"10px"}
                            />
                          </Div>
                        </Div>
                      </Container>
                      <Divider m={"0px !important"}></Divider>
                    </Div>
                  )}
                </>
              ))}
            </StyledCard>
          </Div>
        );
      })}
    </ScrollToTop>
  );
};

OrderConfirmationLoader.propTypes = {
  orderItems: PropTypes.object,
};
export default OrderConfirmationLoader;
