import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useCustomerFormCard = (id, status) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const itemsToRender = React.useMemo(
    () => [
      {
        labelKey: "label_customer_form_id",
        value: id,
      },
      {
        labelKey: "label_status",
        value: status,
      },
    ],
    [id, status]
  );

  const isDialogOpen = React.useMemo(() => {
    const query = new URLSearchParams(search);

    return query.get("id") !== null;
  }, [search]);

  const handleOpenDialog = React.useCallback(() => {
    const query = new URLSearchParams(search);

    query.set("id", id);

    history.replace({
      pathname: pathname,
      search: query.toString(),
    });
  }, [history, id, pathname, search]);

  return { handleOpenDialog, isDialogOpen, itemsToRender };
};
