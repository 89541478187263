import React from "react";
import { FormattedMessage } from "react-intl";

import {
  CASES_DATA_STATUS_TYPE,
  CASES_STATUS_TYPE,
  FORM_INPUT_TYPE,
} from "@utils/enum";
import { addHyphenToNumber, formatDateAndTime } from "@utils/utils";

export const getConfig = data => {
  const configuration = {
    generalData: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.id,
      },
      {
        key: "order_type",
        translationKey: "label_order_type",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.order_type,
        isReadOnlyField: true,
      },
      {
        key: "name",
        translationKey: "label_service_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.order_item?.name,
        isReadOnlyField: true,
      },
      {
        key: "process_type",
        translationKey: "label_process_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_automatic" />,
            value: "automatic",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data?.process_type,
      },
      {
        key: "report_status",
        translationKey: "label_report",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.report_status,
        isReadOnlyField: true,
      },
      {
        key: "candidate_name",
        translationKey: "label_candidates_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.order_item?.person_name,
        isReadOnlyField: true,
      },
      {
        key: "personal_id_number",
        translationKey: "label_candidates_personal_id",
        type: FORM_INPUT_TYPE.TEXT,
        value: addHyphenToNumber(data?.order_item?.personal_number.toString(), 8),
        isReadOnlyField: true,
      },
      {
        key: "orderer_name",
        translationKey: "label_orderer_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.order_user?.name,
        isReadOnlyField: true,
      },
      {
        key: "customer_name",
        translationKey: "label_customers_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.customer_name,
        isReadOnlyField: true,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: formatDateAndTime(data?.created_at),
        isReadOnlyField: true,
      },
      {
        type: FORM_INPUT_TYPE.FILLER,
      },
      {
        key: "data_sources",
        translationKey: "label_data_source",
        type: FORM_INPUT_TYPE.MULTISELECT,
        options: data?.product?.data_source,
        selectorField: "name",
        selectAllLabel: <FormattedMessage id="label_all_sources" />,
        value: data?.data_sources,
        isReadOnlyField: true,
        fullWidth: true,
      },
    ],
    statuses: [
      {
        key: "status",
        translationKey: "label_case_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.IN_PROGRESS}`}
              />
            ),
            value: CASES_STATUS_TYPE.IN_PROGRESS,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.COMPLETED}`}
              />
            ),
            value: CASES_STATUS_TYPE.COMPLETED,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.CANCELLED}`}
              />
            ),
            value: CASES_STATUS_TYPE.CANCELLED,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.MANUAL}`}
              />
            ),
            value: CASES_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.status,
      },
      {
        key: "data_sources_status",
        translationKey: "label_data_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_pending" />,
            value: CASES_DATA_STATUS_TYPE.PENDING,
          },
          {
            label: <FormattedMessage id={CASES_DATA_STATUS_TYPE.COMPLETED} />,
            value: CASES_DATA_STATUS_TYPE.COMPLETED,
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: CASES_DATA_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.data_sources_status,
        isReadOnlyField: true,
      },
    ],
  };

  return configuration;
};
