import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonIcon } from "@components/Button";
import Div from "@components/Div";
import RoundedInputText from "@components/RoundedInputText";
import { Error } from "@components/Heading";

import { useSSNSearchInput } from "./SSNSearchInput.hooks";

const SSNSearchInput = ({ error, onChange, onSubmit }) => {
  const { messages } = useIntl();
  const { handleKeyDown, handleOnSSNNumberChange, handleOnSubmit } =
    useSSNSearchInput(onChange, onSubmit);

  return (
    <Div
      width={1}
      display="flex"
      flexDirection="column"
      gridGap={2}
      justifyContent="flex-end"
    >
      <Div
        width={1}
        display="flex"
        flexDirection={["column", "column", "row"]}
        p={2}
        gridGap={3}
        bg="var(--blue-lightest)"
        borderRadius="30px"
      >
        <RoundedInputText
          width={1}
          height="40px"
          placeholder={messages.placeholder_search}
          onChange={handleOnSSNNumberChange}
          onKeyDown={handleKeyDown}
        />

        <PrimaryButtonIcon
          width={[1, 1, "150px"]}
          label={messages.search_text}
          icon="icon-search"
          onClick={handleOnSubmit}
        />
      </Div>

      {error !== "" && (
        <Div pb={1} pt={3}>
          <Error>{error}</Error>
        </Div>
      )}
    </Div>
  );
};

SSNSearchInput.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SSNSearchInput;
