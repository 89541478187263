export const useSSNSearchInput = (onChange, onSubmit) => {
  const handleOnSSNNumberChange = event => {
    onChange(event.target.value);
  };

  const handleOnSubmit = () => {
    onSubmit();
  };

  const handleKeyDown = e => {
    if (e.key !== "Enter") {
      return;
    }

    handleOnSubmit();
  };

  return {
    handleOnSSNNumberChange,
    handleOnSubmit,
    handleKeyDown,
  };
};
