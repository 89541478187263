export const CUSTOMER_FORM_TYPES = {
  ADDITIONAL_INFO: "additional_info",
  ORDER_WIZARD: "order_wizard",
};

export const CUSTOMER_FORM_CONTENT_BY_TYPE = {
  [CUSTOMER_FORM_TYPES.ADDITIONAL_INFO]: {
    titleKey: "please_fill_the_form",
    submitButtonLabelKey: "label_submit_and_save",
  },
  [CUSTOMER_FORM_TYPES.ORDER_WIZARD]: {
    titleKey: "title_additional_information",
    submitButtonLabelKey: "label_submit_and_continue",
  },
};
