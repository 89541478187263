import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { useFormik } from "formik";

import Card from "@components/Card";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import InputText from "@components/InputText";
import { TextMediumWeight } from "@components/Text";

import { ZipCodeCardSchema } from "./ZipCodeCard.schema";

const ZipCodeCard = ({ ssn, name, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      zip_code: "",
    },
    validationSchema: ZipCodeCardSchema,
  });
  const { messages } = useIntl();

  useEffect(() => {
    if (formik.values.zip_code === "" || !formik.isValid) {
      return;
    }

    const timeoutId = setTimeout(() => {
      onSubmit?.(ssn, formik.values.zip_code);
    }, 800);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [formik.values.zip_code, formik.isValid, onSubmit, ssn]);

  return (
    <Card display="flex" p={3}>
      <Div display="flex" flexDirection="column" gridGap="12px">
        <H4>{`${messages.title_additional_info}: ${name}`}</H4>

        <Div width={1} height={1} bg="var(--grey-lightest)" />

        <Div display="flex" flexDirection="column" gridGap={3}>
          <TextMediumWeight>{messages.text_zip_code_required}</TextMediumWeight>

          <Div mt={-3}>
            <InputText
              name="zip_code"
              label={messages.label_zip_code}
              placeholder={messages.zip_code}
              formikProps={formik}
              value={formik.values.zip_code}
              width={[1, 1, "auto"]}
              maxLength={5}
            />
          </Div>
        </Div>
      </Div>
    </Card>
  );
};

ZipCodeCard.propTypes = {
  name: PropTypes.string,
  ssn: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default ZipCodeCard;
