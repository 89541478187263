import React from "react";
import { useIntl } from "react-intl";

import getDataSources from "@app/services/products/getDataSources";
import updateSourceDataById from "@app/services/cases/updateSourceDataById";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { CASES_DATA_STATUS_TYPE, DATA_SOURCE_TYPE } from "@utils/enum";
import { unFlattenObject } from "@utils/utils";

import {
  handleFormatStatus,
  formatChangeStatus,
  formatChangeStatusIcon,
  formatCrimesLabel,
  isCourtDataSource,
  shouldEmailsActionBeHidden,
  handleRedirectToEmailsDialog,
  handleRedirectToCrimesDialog,
  formatCustomerName,
  handleRedirectToCaseDetails,
  formatProductName,
} from "./ManualDataSourcesTab.utils";

const SEARCHABLE_FIELDS = ["id", "case_id"];

export const useManualDataSourcesTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showErrorToast, showSuccessToast } = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  const [dataSourceFilters, setDataSourceFilters] = React.useState([]);
  const [jsonData, setJsonData] = React.useState(null);

  const hasWriteDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminWriteDataSources,
  ]);

  const shouldStatusActionBeHidden = React.useCallback(
    () => !hasWriteDataSourcesPermission,
    [hasWriteDataSourcesPermission]
  );

  React.useEffect(() => {
    const fetchDataSource = async () => {
      try {
        setIsLoading(true);

        const { data: dataSources = [] } = await getDataSources();
        const formattedDataSources = dataSources
          .filter(dataSource => typeof dataSource === "string")
          .map(dataSource => ({
            name: messages[`datasource_${dataSource}`],
            code: dataSource,
          }));

        setDataSourceFilters(formattedDataSources);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataSource();
  }, [messages, showErrorToast]);

  const formatSourceDataType = React.useCallback(
    (value, db_fields) => {
      const courtName = db_fields["court.court_name"];

      if (value === "court") {
        return messages[`datasource_${value}`] + ` (${courtName}) ` || "-";
      }

      return messages[`datasource_${value}`] || "-";
    },
    [messages]
  );

  const handleOpenDataDialog = React.useCallback(event => {
    const { data } = unFlattenObject(event);

    setJsonData(data);
  }, []);

  const handleUpdateStatus = React.useCallback(
    async ({ id, status, case_id }) => {
      try {
        setIsLoading(true);
        const targetStatus =
          status === CASES_DATA_STATUS_TYPE.MANUAL ||
          status === CASES_DATA_STATUS_TYPE.PENDING
            ? CASES_DATA_STATUS_TYPE.COMPLETED
            : CASES_DATA_STATUS_TYPE.MANUAL;
        const payload = {
          status: targetStatus,
        };
        await updateSourceDataById(case_id, id, payload);
        const successMessage =
          targetStatus === CASES_DATA_STATUS_TYPE.MANUAL ||
          targetStatus === CASES_DATA_STATUS_TYPE.IN_PROGRESS
            ? messages.label_changed_to_manual
            : messages.label_changed_to_completed;
        showSuccessToast(successMessage);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      messages.exception_error_message,
      messages.label_changed_to_completed,
      messages.label_changed_to_manual,
      showErrorToast,
      showSuccessToast,
    ]
  );

  const shouldCrimesActionBeHidden = React.useCallback(
    ({ type }) => {
      const isCourtType =
        isCourtDataSource(type) ||
        type === DATA_SOURCE_TYPE.VERIFERA_PERSON_INFORMATION_ROLE_ANALYSIS;
      return !isCourtType || !hasWriteDataSourcesPermission;
    },
    [hasWriteDataSourcesPermission]
  );

  const handleCloseDataDialog = React.useCallback(() => {
    setJsonData(null);
  }, []);

  const config = React.useMemo(
    () => ({
      header: {
        actions: [],
        dataTableActions: [
          {
            id: "filter",
            type: "button-tooltip",
            icon: "filter",
            variant: "header",
            borderRadius: 0,
            content: messages.order_filter_description,
          },
        ],
      },
      enable_filter: true,
      is_filter_options_updated: dataSourceFilters.length > 1,
      filters: [
        {
          title: messages.label_cases_data_source,
          id: "filter[type]",
          type: "multiselect",
          options: [
            {
              name: "filter[type]",
              value: "",
              placeholder: messages.watchlist_label_choose,
              id: "filter[type]",
              options: dataSourceFilters,
              type: "multiselect",
            },
          ],
        },
        {
          title: messages.label_status,
          id: "filter[status]",
          type: "checkbox",
          options: [
            {
              label: messages.label_cases_status_pending,
              value: CASES_DATA_STATUS_TYPE.PENDING,
              checked: false,
            },
            {
              label: messages.label_manual,
              value: CASES_DATA_STATUS_TYPE.MANUAL,
              checked: true,
            },
            {
              label: messages[CASES_DATA_STATUS_TYPE.COMPLETED],
              value: CASES_DATA_STATUS_TYPE.COMPLETED,
              checked: false,
            },
          ],
        },
      ],
      backend_querying: true,
      no_records_message: "no_results_found",
      enable_search: true,
      enable_query_builder: true,
      api: {
        resource: "/source_data",
        method: "GET",
        search_fields: SEARCHABLE_FIELDS,
        params: [
          ["include", ["court"]],
          ["filter[status]", CASES_DATA_STATUS_TYPE.MANUAL],
        ],
      },
      columns: [
        {
          db_field: "id",
          title: "label_id",
          type: "id",
          width: "6%",
          sortable: true,
          plainText: true,
        },
        {
          db_field: "case_id",
          title: "label_case_id",
          type: "id",
          className: "text-blue",
          width: "7%",
          sortable: true,
          href: handleRedirectToCaseDetails,
        },
        {
          db_field: "customer_name",
          title: "title_customer",
          type: "text",
          width: "17%",
          sortable: true,
          formatter: formatCustomerName,
        },
        {
          db_field: "created_at",
          title: "label_created",
          type: "dateTime",
          width: "15%",
          sortable: true,
        },
        {
          db_field: "product_name",
          title: "label_product",
          type: "text",
          width: "17%",
          sortable: true,
          formatter: formatProductName,
        },
        {
          db_field: "status",
          title: "label_status",
          type: "enum",
          width: "8%",
          sortable: true,
          formatter: handleFormatStatus,
        },
        {
          db_field: "type",
          title: "label_cases_data_source",
          type: "text",
          formatter: formatSourceDataType,
          width: "25%",
          sortable: true,
        },
        {
          type: "actions",
          width: "5%",
          actions: [
            {
              label: messages.label_show_data,
              icon: "icon-file-download",
              onClick: handleOpenDataDialog,
            },
            {
              label: messages.label_change_to_manual,
              iconSelector: formatChangeStatusIcon,
              onClick: handleUpdateStatus,
              formatter: formatChangeStatus,
              shouldHideAction: shouldStatusActionBeHidden,
            },
            {
              label: messages.label_edit_add_crimes,
              type: "link",
              icon: "icon-menu-report",
              formatter: formatCrimesLabel,
              href: handleRedirectToCrimesDialog,
              shouldHideAction: shouldCrimesActionBeHidden,
            },
            {
              label: messages.label_view_emails,
              type: "link",
              icon: "icon-mail",
              href: handleRedirectToEmailsDialog,
              shouldHideAction: shouldEmailsActionBeHidden,
            },
          ],
        },
      ],
    }),
    [
      dataSourceFilters,
      formatSourceDataType,
      handleOpenDataDialog,
      handleUpdateStatus,
      messages,
      shouldCrimesActionBeHidden,
      shouldStatusActionBeHidden,
    ]
  );

  return { config, isLoading, jsonData, handleCloseDataDialog };
};
