import React from "react";
import { FormattedMessage } from "react-intl";

import { ROUTES } from "@utils/constant";
import { CASES_DATA_STATUS_TYPE, DATA_SOURCE_TYPE } from "@utils/enum";

export const handleFormatStatus = value => {
  const statusTranslationsByValue = {
    [CASES_DATA_STATUS_TYPE.PENDING]: (
      <FormattedMessage id="label_cases_status_pending" />
    ),
    [CASES_DATA_STATUS_TYPE.MANUAL]: <FormattedMessage id="label_manual" />,
    [CASES_DATA_STATUS_TYPE.COMPLETED]: (
      <FormattedMessage id={CASES_DATA_STATUS_TYPE.COMPLETED} />
    ),
  };

  return statusTranslationsByValue[value];
};

export const formatChangeStatusIcon = ({ status }) => {
  const shouldChangeToCompleted =
    status === CASES_DATA_STATUS_TYPE.MANUAL ||
    status === CASES_DATA_STATUS_TYPE.PENDING;

  return shouldChangeToCompleted ? "icon-tick-mark" : "icon-pen";
};

export const formatChangeStatus = ({ status }) => {
  const shouldChangeToCompleted =
    status === CASES_DATA_STATUS_TYPE.MANUAL ||
    status === CASES_DATA_STATUS_TYPE.PENDING;

  return shouldChangeToCompleted ? (
    <FormattedMessage id="label_change_to_completed" />
  ) : (
    <FormattedMessage id="label_change_to_manual" />
  );
};

export const formatCrimesLabel = ({ status }) => {
  return status === CASES_DATA_STATUS_TYPE.COMPLETED ? (
    <FormattedMessage id="label_view_crimes" />
  ) : (
    <FormattedMessage id="label_edit_add_crimes" />
  );
};

export const isCourtDataSource = type => {
  const courtTypes = [
    DATA_SOURCE_TYPE.COURT_SMALL,
    DATA_SOURCE_TYPE.COURT_MEDIUM,
    DATA_SOURCE_TYPE.COURT_LARGE,
    DATA_SOURCE_TYPE.COURT,
  ];

  return courtTypes.includes(type);
};

export const shouldEmailsActionBeHidden = ({ type }) => {
  return !isCourtDataSource(type);
};

export const handleRedirectToEmailsDialog = ({ id, case_id }) =>
  ROUTES.ADMIN_CASE_EMAIL_THREAD.URL.replace(":case_id", case_id).replace(
    ":data_source_id",
    id
  );

export const handleRedirectToCrimesDialog = ({ id, case_id }) =>
  ROUTES.ADMIN_CASE_CRIMES.URL.replace(":case_id", case_id).replace(
    ":data_source_id",
    id
  );

export const formatCustomerName = value => value ?? "-";

export const handleRedirectToCaseDetails = ({ case_id }) =>
  ROUTES.ADMIN_CASE_DETAILS.URL.replace(":case_id", case_id);

export const formatProductName = value => value ?? "-";
