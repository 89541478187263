import styled from "styled-components";
import { RadioButton as PRRadio } from "primereact/radiobutton";
import { compose, color, layout, space } from "styled-system";
const RadioButton = styled(PRRadio).attrs({
  type: "radio",
})`
  ${compose(color, layout, space)}

  .p-radiobutton-input {
    appearance: none;
    display: grid;
    place-content: center;

    width: 20px;
    height: 20px;
    border: 2px solid var(--turquoise);
    border-radius: 50%;
  }

  .p-radiobutton-input:hover {
    opacity: 0.75;
  }

  .p-radiobutton-input:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--turquoise);
    border-radius: 50%;
    transition: 120ms transform ease-in-out;
  }

  .p-radiobutton-box {
    display: none;
  }
`;

export default RadioButton;
