import React from "react";
import { FormattedMessage } from "react-intl";
import CompanyType from "@constants/companyTypeOptions";
import ConsentDropDownOptions from "@constants/consentDropDownOptions";
import ContentOptions from "@constants/contentOptions";
import CustomerStatus from "@constants/customerStatus";
import DeliveryDropDown from "@constants/customerDocumentDeliveryOption";
import DeliveryFrequencyOptions from "@constants/deliveryFrequencyOptions";
import DeliveryMethodOptions from "@constants/deliveryMethodOptions";
import YesnoOptions from "@constants/yesnoOptions";

import { FORM_INPUT_TYPE } from "@utils/enum";
import { formatDate } from "@utils/utils";

const getConfig = (
  companyDetails,
  users,
  created_by,
  customerData,
  customerConsent
) => {
  const consentOptions = ConsentDropDownOptions();
  const yesnoOptions = YesnoOptions();
  const customerStatus = CustomerStatus();
  const companyTypeOptions = CompanyType();

  const company = [
    {
      key: "customer_name",
      translationKey: "label_company_name",
      value: companyDetails?.customer_name,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: companyDetails?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
      maxLength: 11,
    },
    {
      key: "address",
      translationKey: "label_company_address",
      value: companyDetails?.address,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: companyDetails?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: companyDetails?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "account_manager_id",
      translationKey: "account_manager",
      value: companyDetails?.account_manager_id,
      type: FORM_INPUT_TYPE.DROPDOWN,
      options: users,
      selectorField: "label",
    },
    {
      key: "created_by",
      translationKey: "label_created_by",
      value: created_by
        ? `${created_by?.firstname} ${created_by?.lastname}`
        : "",
      type: FORM_INPUT_TYPE.TEXT,
      editable: false,
    },
    {
      key: "created_at",
      translationKey: "created_at",
      value: customerData.createdAt,
      type: FORM_INPUT_TYPE.CALENDAR,
      editable: false,
    },
  ];

  const contact = [
    {
      key: "firstname",
      translationKey: "label_firstname",
      value: companyDetails?.firstname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "lastname",
      translationKey: "label_last_name",
      value: companyDetails?.lastname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "email",
      translationKey: "label_email",
      value: companyDetails?.email,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "phone",
      translationKey: "label_phone",
      value: companyDetails?.phone,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
  ];

  const settings = [
    {
      key: "consent",
      translationKey: "consent_title",
      value: customerConsent,
      type: FORM_INPUT_TYPE.DROPDOWN,
      mt: 3,
      name: "consentValue",
      options: consentOptions,
      width: [1, 1, 450, 450],
      required: true,
    },
    {
      key: "is_active",
      translationKey: "consent_label_status",
      value: companyDetails?.is_active,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "is_active",
      options: customerStatus,
      required: true,
    },
    {
      key: "order_confirmation_email",
      translationKey: "order_email_notification",
      value: companyDetails?.order_confirmation_email,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "order_confirmation",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "integration_api_enabled",
      translationKey: "api_integration",
      value: companyDetails?.integration_api_enabled,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "api_integration",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "test_account",
      translationKey: "test_account",
      value: companyDetails?.test_account,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "testaccount",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "company_type",
      translationKey: "label_company_type",
      value: companyDetails?.company_type,
      type: FORM_INPUT_TYPE.DROPDOWN,
      options: companyTypeOptions,
      required: true,
    },
  ];

  const pub = [
    {
      key: "pub_agreement",
      translationKey: "pub_agreement",
      value: companyDetails?.pub_agreement,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "PUBAgreement",
      options: yesnoOptions,
      selectorField: "label",
      required: true,
    },
    {
      key: "pub_date",
      translationKey: "pub_date",
      value: formatDate(companyDetails?.pub_date),
      type: FORM_INPUT_TYPE.CALENDAR,
      isHidden: companyDetails?.pub_agreement === 0,
    },
    {
      key: "document",
      translationKey: "customer_pub_document",
      type: FORM_INPUT_TYPE.FILE_UPLOAD,
      label: "Upload File",
      value: companyDetails?.document,
      isHidden: companyDetails?.pub_agreement === 0,
    },
  ];

  const executionConfig = [
    {
      key: "delivery_time",
      translationKey: "admin_customer_details_delivery_time",
      value: companyDetails?.delivery_time,
      type: FORM_INPUT_TYPE.TEXT,
    },
    {
      key: "delivery_place",
      translationKey: "admin_customer_details_delivery_place",
      value: companyDetails?.delivery_place,
      type: FORM_INPUT_TYPE.TEXT,
    },
    {
      key: "delivery_description",
      translationKey: "admin_customer_details_delivery_description",
      value: companyDetails?.delivery_description,
      type: FORM_INPUT_TYPE.EDITOR,
    },
    {
      key: "interview_template",
      translationKey: "admin_customer_details_interview_template",
      value: companyDetails?.interview_template,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "interview_template",
      options: DeliveryDropDown,
      selectorField: "label",
    },
  ];
  const deliveryConfig = [
    {
      key: "delivery_frequency",
      translationKey: "admin_customer_details_delivery_frequency",
      value: companyDetails?.delivery_frequency,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "delivery_frequency",
      options: DeliveryFrequencyOptions,
      selectorField: "label",
    },
    {
      key: "delivery_method",
      translationKey: "admin_customer_details_delivery_method",
      value: companyDetails?.delivery_method,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "delivery_method",
      options: DeliveryMethodOptions,
      selectorField: "label",
    },
    {
      key: "delivery_frequency_other",
      translationKey: "admin_customer_details_other",
      value: companyDetails?.delivery_frequency_other,
      type: FORM_INPUT_TYPE.EDITOR,
    },
    {
      key: "content",
      translationKey: "admin_customer_details_content",
      type: FORM_INPUT_TYPE.EDITOR,
      options: ContentOptions,
      selectorField: "name",
      selectAllLabel: <FormattedMessage id="label_all_sources" />,
      value: companyDetails?.content,
      fullWidth: true,
    },
  ];

  const documentConfig = [
    {
      fieldType: "multifiles",
      name: "delivery_documents",
      value: companyDetails?.delivery_documents,
      placeholder: <FormattedMessage id="delivery_time_label" />,
    },
  ];

  return {
    company,
    contact,
    settings,
    pub,
    executionConfig,
    deliveryConfig,
    documentConfig,
  };
};

export { getConfig };
