import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { useUser } from "@contexts/AuthProvider";
import { OrderProvider } from "@contexts/OrderContext";
import { StepperProvider } from "@contexts/StepperContext";

import { ROUTES } from "@utils/constant";

import {
  AvailableServicesSSNNotAvailable,
  IndividualSSNNotAvailable,
} from "./components";
import OrderSSNNotAvailableStepper from "./OrderSSNNotAvailableStepper";
import {
  Checkout as CheckoutComponent,
  OrderConfirmation as OrderConfirmationComponent,
  ChooseCustomer,
} from "../CreateOrderWizard/components";

const STEPS = [
  {
    label: <FormattedMessage id="label_start" />,
    component: <IndividualSSNNotAvailable />,
    url: ROUTES.ORDER_SSN_NOT_AVAILABLE_DETAILS.URL,
  },
  {
    label: <FormattedMessage id="title_select_services" />,
    component: <AvailableServicesSSNNotAvailable />,
    url: ROUTES.ORDER_SSN_NOT_AVAILABLE_SELECT_SERVICES.URL,
  },
  {
    label: <FormattedMessage id="title_checkout" />,
    component: <CheckoutComponent />,
    url: ROUTES.ORDER_SSN_NOT_AVAILABLE_CHECKOUT.URL,
  },
  {
    label: <FormattedMessage id="label_confirm_order" />,
    component: <OrderConfirmationComponent />,
    url: ROUTES.ORDER_SSN_NOT_AVAILABLE_ORDER_CONFIRMATION.URL,
  },
];

const ADMIN_STEPS_ITEMS = [
  {
    label: <FormattedMessage id="label_choose_customer" />,
    component: <ChooseCustomer />,
    url: ROUTES.ADMIN_ORDER_SSN_NOT_AVAILABLE_SELECT_CUSTOMER.URL,
  },
];

const OrderSSNNotAvailable = () => {
  const { isAdmin } = useUser();

  const steps = useMemo(
    () => (isAdmin ? [...ADMIN_STEPS_ITEMS, ...STEPS] : STEPS),
    [isAdmin]
  );

  return (
    <OrderProvider>
      <StepperProvider steps={steps}>
        <OrderSSNNotAvailableStepper />
      </StepperProvider>
    </OrderProvider>
  );
};

export default OrderSSNNotAvailable;
