import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "@contexts/AuthProvider";
import { useOrder } from "@contexts/OrderContext";

import Stepper from "@pages/shared/Wizard/Stepper";

import { ROUTES } from "@utils/constant";

const OrderWithNoSSNStepper = () => {
  const history = useHistory();
  const { isLoading, orderUser } = useOrder();
  const { isAdmin } = useUser();

  const handleClose = () => {
    history.push(ROUTES.SEARCH.URL);
  };

  useEffect(() => {
    if (orderUser) {
      return;
    }

    const targetUrl = isAdmin
      ? ROUTES.ADMIN_ORDER_WITH_NO_SSN_SELECT_CUSTOMER.URL
      : ROUTES.ORDER_WITH_NO_SSN_DETAILS.URL;

    history.push(targetUrl);
  }, [history, isAdmin, orderUser]);

  return <Stepper isLoading={isLoading} onClose={handleClose} />;
};

export default OrderWithNoSSNStepper;
