import React from "react";
import { useIntl } from "react-intl";

import ForIndividuals from "@assets/for_individuals.jpg";

import Card from "@components/Card";
import Div from "@components/Div";
import { H1, H3 } from "@components/Heading";
import Logo from "@components/Logo";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";
import { TextMediumWeight } from "@components/Text";

import UserContainer from "@layout/UserContainer";

import { SearchOptionCard, SSNSearchInput } from "./components";
import { OTHER_OPTIONS } from "./Search.constants";
import { useSearch } from "./Search.hooks";

const Search = () => {
  const { messages } = useIntl();
  const { handleSearch, setSsnNumber, ssnError } = useSearch();

  const headerConfig = {
    title: messages.search_text,
  };

  return (
    <UserContainer
      hideGdprInfo
      config={headerConfig}
      justifyContent="space-between"
    >
      <Div
        w={1}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Div display="flex" flexDirection="column" w={1} gridGap={4}>
          <Div
            width={1}
            display="flex"
            flexDirection={["column-reverse", "column-reverse", "row"]}
            gridGap={3}
          >
            <Card flex={1} p={3} borderRadius="10px">
              <Div
                display="flex"
                flexDirection="column"
                gridGap={4}
                justifyContent="space-between"
                minHeight={["auto", "auto", "255px"]}
              >
                <Div display="flex" flexDirection="column" gridGap="1.5rem">
                  <H1>{messages.title_individual_with_ssn}</H1>

                  <TextMediumWeight color="var(--grey) !important">
                    {messages.details_individual_with_ssn}
                  </TextMediumWeight>
                </Div>

                <SSNSearchInput
                  onChange={setSsnNumber}
                  onSubmit={handleSearch}
                  error={ssnError}
                />
              </Div>
            </Card>

            <Logo
              flex={1}
              logo={ForIndividuals}
              borderRadius="10px"
              maxWidth={["100%", "100%", "50%"]}
            />
          </Div>

          <Div width={1} display="flex" flexDirection="column" gridGap={3}>
            <H3 textTransform="none">{messages.title_other_options}</H3>

            <Div width={1} display="flex" flexDirection="column" gridGap="24px">
              {OTHER_OPTIONS.map((sectionElements, sectionIndex) => (
                <Div
                  key={sectionIndex}
                  width={1}
                  display="flex"
                  flexDirection={["column", "column", "row"]}
                  alignItems="flex-start"
                  gridGap="24px"
                >
                  {sectionElements
                    .filter(({ isHidden }) => !isHidden)
                    .map(option => (
                      <SearchOptionCard key={option.iconName} {...option} />
                    ))}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>

        <Div mt="5rem" width={["95%", "95%", "95%", "80%"]} bottom="0px">
          <PrivacyPolicyFooter />
        </Div>
      </Div>
    </UserContainer>
  );
};

export default Search;
