import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import getFormDetails from "@app/services/orders/getFormDetails";
import sendOrderCustomerFormService from "@app/services/orders/sendOrderCustomerFormService";
import { useUser } from "@contexts/AuthProvider";

import { useToast } from "@hooks/useToast";

import { ROUTES, STATUS_COMPLETED } from "@utils/constant";
import { SURVEY_MODE_TYPE } from "@utils/enum";

export const useCustomerForm = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { order_id } = useParams();
  const { showErrorToast, showSuccessToast } = useToast();
  const { isCustomer } = useUser();
  const { order } = useSelector(state => state.klarnaReducer.ordersInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fetchedOrder, setFetchedOrder] = useState(null);
  
  const customerFormId = useMemo(() => {
    return order_id ?? order?.order_customer_form?.id;
  }, [order?.order_customer_form?.id, order_id]);

  const surveyMode = useMemo(
    () => (isSubmitted ? SURVEY_MODE_TYPE.DISPLAY : SURVEY_MODE_TYPE.EDIT),
    [isSubmitted]
  );

  const config = useMemo(() => {
    if (!fetchedOrder) return null;

    return JSON.stringify(fetchedOrder?.form_config);
  }, [fetchedOrder]);

  const formData = useMemo(
    () => fetchedOrder?.form_data,
    [fetchedOrder?.form_data]
  );

  const personDetails = useMemo(() => {
    const { order_items: { personal_number = "", person_name = "" } = {} } =
      fetchedOrder ?? {};

    if (!personal_number && !person_name) {
      return "";
    }

    if (!personal_number) {
      return person_name;
    }

    if (!person_name) {
      return personal_number;
    }

    return `${person_name} (${personal_number})`;
  }, [fetchedOrder]);

  const surveyRef = useRef(null);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!customerFormId) return;

        const { data: fetchedOrderData } = await getFormDetails(customerFormId);
        setFetchedOrder(fetchedOrderData);
        setIsSubmitted(fetchedOrderData?.form_data != null);
      } catch (error) {
        setFetchedOrder({});
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerFormId]);

  const sendCustomerFormData = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = { status: STATUS_COMPLETED, form_data: data };

        await sendOrderCustomerFormService(customerFormId, payload);

        const { data: fetchedOrderData } = await getFormDetails(customerFormId);

        setFetchedOrder(fetchedOrderData);
        setIsSubmitted(fetchedOrderData?.form_data != null);

        showSuccessToast(messages.text_update_successful);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      customerFormId,
      showSuccessToast,
      messages.text_update_successful,
      messages.exception_error_message,
      showErrorToast,
    ]
  );

  const handleSave = useCallback(async () => {
    const { current } = surveyRef;

    if (!current) {
      return;
    }

    const { data } = current;

    sendCustomerFormData(data);
  }, [sendCustomerFormData]);

  const handleSkip = useCallback(() => {
    const targetRoute = isCustomer
      ? ROUTES.ORDERS_LIST.URL
      : ROUTES.ADMIN_ORDERS_LIST.URL;

    history.push(targetRoute);
  }, [history, isCustomer]);

  const handleNewSearch = useCallback(() => {
    history.push(ROUTES.WIZARD_SEARCH.URL);
  }, [history]);

  return {
    config,
    formData,
    isLoading,
    isSubmitted,
    personDetails,
    surveyMode,
    handleLoadSurveyRef,
    handleNewSearch,
    handleSave,
    handleSkip,
  };
};
