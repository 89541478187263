import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@components/Card";
import Container from "@components/Container";
import Div from "@components/Div";
import { H5 } from "@components/Heading";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";

import { CustomerPermissions } from "@src/enum/Permissions";

import { ORDER_TYPE } from "@utils/enum";
import { addHyphenToNumber } from "@utils/utils";

import OrderItemCard from "./OrderItemCard";
import ResultsCard from "./ResultsCard";

const CandidateOrderDetails = ({
  orderItems,
  assignments = [],
  isOrderDetails,
  isOrderConfirmation,
  orderStatus,
  orderType,
}) => {
  const { hasAllPermissions, isAdmin } = useAuthorization();
  const { messages } = useIntl();
  const personInfo = useSelector(
    state => state.personInformationReducer.personInfo
  );

  const hasPricesPermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const assignmentInducedOrderItems = orderItems.map(orderItem => {
    const correspondingAssignment = assignments.find(
      assignment => assignment.order_item.id === orderItem.id
    );

    return {
      ...orderItem,
      assignment: correspondingAssignment || null,
    };
  });
  const groupedData = assignmentInducedOrderItems.reduce((result, item) => {
    const category = item.personal_number;
    if (!result[category]) {
      result[category] = {
        items: [],
        person_name:
          hasPricesPermission && !isOrderConfirmation
            ? item.person_initials
            : item.person_name,
        personal_number: item.personal_number,
        date_of_birth: item.date_of_birth,
        organisation_number: item.organisation_number,
        id: item.id,
      };
    }
    result[category].items.push(item);
    return result;
  }, {});

  const getOrderHeaderInfo = details => {
    const {
      person_name,
      date_of_birth,
      organisation_number,
      personal_number,
      company_name,
      coordination_number,
    } = details;

    switch (orderType) {
      case ORDER_TYPE.INDIVIDUAL_WITHOUT_SSN: {
        const [
          { person_first_name, person_last_name, date_of_birth: dateOfBirth },
        ] = personInfo ?? [{}];

        return {
          name: {
            title:
              isAdmin || isOrderConfirmation
                ? messages["candidate_order_details.name"]
                : messages["candidate_order_details.initials"],
            value:
              person_name ||
              [person_first_name, person_last_name].join(" ").trim(),
          },
          searchIdentifier: {
            title: messages["label_date_of_birth"],
            value: date_of_birth ?? dateOfBirth,
          },
        };
      }

      case ORDER_TYPE.COMPANY: {
        const [
          {
            company_name: companyName,
            organisation_number: organisationNumber,
          },
        ] = personInfo ?? [{}];

        return {
          name: {
            title: messages["company_name"],
            value: company_name ?? companyName,
          },
          searchIdentifier: {
            title: messages["label_organisation_number"],
            value: organisation_number ?? organisationNumber,
          },
        };
      }

      case ORDER_TYPE.INDIVIDUAL_COORDINATION_NUMBER: {
        const [
          {
            person_first_name,
            person_last_name,
            coordination_number: coordinationNumber,
          },
        ] = personInfo ?? [{}];

        return {
          name: {
            title:
              isAdmin || isOrderConfirmation
                ? messages["candidate_order_details.name"]
                : messages["candidate_order_details.initials"],
            value:
              person_name ||
              [person_first_name, person_last_name].join(" ").trim(),
          },
          searchIdentifier: {
            title: messages["label_coordination_number"],
            value: coordination_number ?? coordinationNumber,
          },
        };
      }

      case ORDER_TYPE.INDIVIDUAL_SSN_NOT_AVAILABLE: {
        const [{ person_first_name, person_last_name, ssn }] = personInfo ?? [
          {},
        ];

        return {
          name: {
            title:
              isAdmin || isOrderConfirmation
                ? messages["candidate_order_details.name"]
                : messages["candidate_order_details.initials"],
            value:
              person_name ||
              [person_first_name, person_last_name].join(" ").trim(),
          },
          searchIdentifier: {
            title: messages["candidate_order_details.label_personal_number"],
            value: addHyphenToNumber((ssn ?? personal_number)?.toString(), 8),
          },
        };
      }

      default:
        return {
          name: {
            title:
              isAdmin || isOrderConfirmation
                ? messages["candidate_order_details.name"]
                : messages["candidate_order_details.initials"],
            value: person_name,
          },
          searchIdentifier: {
            title: messages["candidate_order_details.label_personal_number"],
            value: addHyphenToNumber(personal_number?.toString(), 8),
          },
        };
    }
  };

  return (
    <>
      {Object.keys(groupedData).map(category => {
        const { items } = groupedData[category];

        const parentChildMap = {};

        items.forEach(item => {
          if (item.parent === null) {
            if (!parentChildMap[item.product_id]) {
              parentChildMap[item.product_id] = { ...item, children: [] };
            }
          }
        });

        items.forEach(item => {
          if (item.parent !== null && parentChildMap[item.parent]) {
            parentChildMap[item.parent].children.push(item);
          }
        });

        const candidateItems = Object.values(parentChildMap).filter(
          item => item.parent === null
        );

        const headerInfo = getOrderHeaderInfo(groupedData[category]);

        const header = (
          <Div width={1} p={3}>
            <Div
              display="flex"
              alignItems={["left", "center"]}
              justifyContent="space-between"
              flexDirection={["column", "row"]}
              gridGap={[3, "20px"]}
            >
              <Div
                display="flex"
                flexDirection={["row", "column"]}
                justifyContent="space-between"
                alignItems={["center", "flex-start"]}
                gridGap={[0, "10px"]}
              >
                <Text>{headerInfo.name.title}</Text>

                <TextLargeSemiBoldWeight
                  fontSize={[
                    "var(--fs-text-m) !important",
                    "var(--fs-text-m) !important",
                    "var(--fs-text)",
                    "var(--fs-text)",
                  ]}
                  mt={[0, 0, 2, 2]}
                >
                  {headerInfo.name.value}
                </TextLargeSemiBoldWeight>
              </Div>

              {(isAdmin || isOrderConfirmation) && (
                <Div
                  display="flex"
                  flexDirection={["row", "column"]}
                  justifyContent="space-between"
                  alignItems={["center", "flex-end"]}
                  gridGap={[0, "10px"]}
                >
                  <Text>{headerInfo.searchIdentifier.title}</Text>

                  <TextLargeSemiBoldWeight
                    fontSize={[
                      "var(--fs-text-m) !important",
                      "var(--fs-text-m) !important",
                      "var(--fs-text)",
                      "var(--fs-text)",
                    ]}
                    mt={[0, 0, 2, 2]}
                  >
                    {headerInfo.searchIdentifier.value}
                  </TextLargeSemiBoldWeight>
                </Div>
              )}
            </Div>
          </Div>
        );

        return (
          <Div key={category}>
            <Card p={0} mb={4} maxWidth="1110px" header={header}>
              <Div>
                <Container width={1} minWidth={["0px", "610px"]}>
                  <Div
                    p={3}
                    maxHeight="50px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderTop="1px solid var(--grey-lightest)"
                  >
                    <Div flex={1}>
                      <H5>
                        {messages["candidate_order_details.title_services"]}
                      </H5>
                    </Div>

                    {isOrderConfirmation && (
                      <Div
                        display={["none", "none", "flex", "flex"]}
                        justifyContent="flex-end"
                        minWidth={["0px", "150px"]}
                      >
                        <H5>
                          {
                            messages[
                              "candidate_order_details.title_delivery_time"
                            ]
                          }
                        </H5>
                      </Div>
                    )}

                    {isOrderDetails && hasPricesPermission && (
                      <Div
                        display={["none", "block", "block", "block"]}
                        textAlign="right"
                        minWidth={["0px", "125px"]}
                      >
                        <H5>{messages["candidate_order_details.price"]}</H5>
                      </Div>
                    )}
                  </Div>

                  {candidateItems.map(item => (
                    <>
                      <Div
                        p={3}
                        key={item.id}
                        borderTop="1px solid var(--grey-lightest)"
                      >
                        {item.product.is_case ? (
                          <OrderItemCard
                            orderItem={item}
                            report={item.report}
                            product={item?.product}
                            isOrderDetails={isOrderDetails}
                            isOrderConfirmation={isOrderConfirmation}
                          />
                        ) : (
                          <ResultsCard
                            isDetails={isOrderDetails}
                            isChild={false}
                            orderItem={item}
                            status={orderStatus}
                            report={item.report || {}}
                            assignment={item.assignment}
                            product={item?.product}
                          />
                        )}
                      </Div>

                      <>
                        {item.children &&
                          item.children.map(childItem => (
                            <Div
                              p={3}
                              key={childItem.id}
                              borderTop="1px solid var(--grey-lightest)"
                            >
                              {item.product.is_case ? (
                                <OrderItemCard
                                  isChild
                                  orderItem={childItem}
                                  report={childItem.report}
                                  product={childItem?.product}
                                  isOrderDetails={isOrderDetails}
                                  isOrderConfirmation={isOrderConfirmation}
                                />
                              ) : (
                                <ResultsCard
                                  isDetails={isOrderDetails}
                                  isChild={true}
                                  orderItem={childItem}
                                  status={orderStatus}
                                  report={childItem.report || {}}
                                  assignment={childItem.assignment}
                                  product={childItem?.product}
                                />
                              )}
                            </Div>
                          ))}
                      </>
                    </>
                  ))}
                </Container>
              </Div>
            </Card>
          </Div>
        );
      })}
    </>
  );
};
CandidateOrderDetails.propTypes = {
  assignments: PropTypes.array,
  orderItems: PropTypes.array,
  isOrderDetails: PropTypes.bool,
  isOrderConfirmation: PropTypes.bool,
  orderStatus: PropTypes.string,
  orderType: PropTypes.string,
};
export default CandidateOrderDetails;
