import React from "react";

import AvailableServices from "@pages/shared/AvailableServices";

import { ORDER_TYPE } from "@utils/enum";
import { AvailableServicesHeader } from "./components";

const AvailableServicesForCoordinationNumber = () => {
  return (
    <AvailableServices
      orderType={ORDER_TYPE.INDIVIDUAL_COORDINATION_NUMBER}
      header={<AvailableServicesHeader />}
    />
  );
};

export default AvailableServicesForCoordinationNumber;
