import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text, TextMediumWeight } from "@components/Text";

import { CustomerFormDialog } from "./components";
import { useCustomerFormCard } from "./CustomerFormCard.hooks";

const CustomerFormCard = ({ id, status }) => {
  const { handleOpenDialog, isDialogOpen, itemsToRender } = useCustomerFormCard(
    id,
    status
  );
  const { messages } = useIntl();

  return (
    <>
      {isDialogOpen && <CustomerFormDialog />}

      <Card borderRadius="10px">
        <Div p={3} display="flex" flexDirection="column" gridGap={3}>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridGap="12px"
          >
            <H4>{messages.title_customer_form}</H4>

            <ButtonLinkIcon
              p={0}
              label={
                <TextMediumWeight>{messages.label_check_form}</TextMediumWeight>
              }
              iconPos="right"
              onClick={handleOpenDialog}
            >
              <Icon
                ml={1}
                name="arrowright"
                color="var(--turquoise)"
                fontSize="var(--fs-icon-m)"
              />
            </ButtonLinkIcon>
          </Div>

          <Div width={1} height={1} bg="var(--grey-lightest)" />

          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
            gridGap={3}
          >
            {itemsToRender.map(({ labelKey, value }) => (
              <Div key={labelKey} flex="1 1 40%">
                <TextMediumWeight>
                  {`${messages[labelKey]}: `}

                  <Text>
                    {messages[`label_${value}`] ?? messages[value] ?? value}
                  </Text>
                </TextMediumWeight>
              </Div>
            ))}
          </Div>
        </Div>
      </Card>
    </>
  );
};

CustomerFormCard.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
};

export default CustomerFormCard;
