import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useFormik } from "formik";

import generateOtpService from "@app/services/auth/generateOtpService";
import verifyOtpService from "@app/services/auth/verifyOtpService";

import {
  ButtonLink,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { InputOtp } from "@components/Input";
import ProgressSpinner from "@components/ProgressSpinner";
import { TextMediumWeight } from "@components/Text";

import { BANK_ID_SCOPES } from "@utils/enum";

import { useCountdown } from "@hooks";

import { OTP_LENGTH, RESET_CODE_COUNTDOWN_LIMIT } from "./constants";
import { OtpValidationSchema } from "./schema";

import {
  useCandidateLoginSharedData,
  useCandidateLoginStepper,
} from "../context";

const INITIAL_VALUES = {
  otp: "",
};

const CandidateLoginOtpStep = () => {
  const { countdown: resendCodeCountdown, resetCountdown } = useCountdown(
    RESET_CODE_COUNTDOWN_LIMIT
  );
  const { sharedData } = useCandidateLoginSharedData();
  const { onPrevStep } = useCandidateLoginStepper();
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const { candidateId, candidateEmail, onError, onSuccess } = sharedData;
  const isResendAvailable = resendCodeCountdown === 0;

  const handleRequestOtpOnEmail = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        candidate_id: candidateId,
        candidate_email: candidateEmail,
        scope: BANK_ID_SCOPES.LOGIN_CANDIDATE,
      };

      await generateOtpService(payload);

      resetCountdown();
    } catch (error) {
      onError(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [
    candidateEmail,
    candidateId,
    messages.exception_error_message,
    onError,
    resetCountdown,
  ]);

  useEffect(() => {
    handleRequestOtpOnEmail();
  }, [handleRequestOtpOnEmail]);

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      const payload = {
        code: values.otp,
      };

      const response = await verifyOtpService(payload);

      if (response.status !== 200) {
        onError(messages.exception_error_message);
      return;
      }

      onSuccess();
    } catch (error) {
      onError(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: OtpValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleOtpChange = value => {
    formik.setFieldValue("otp", value);
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      <H2 color="var(--blue-dark) !important">{messages.title_otp_code}</H2>

      <TextMediumWeight textAlign="center" width="90%">
        <FormattedMessage
          id="message_otp_code_description"
          values={{
            email: candidateEmail,
          }}
        />
      </TextMediumWeight>

      <Div
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Div mb={3}>
          <InputOtp
            name="otp"
            numInputs={OTP_LENGTH}
            value={formik.values.otp}
            onChange={handleOtpChange}
          />
        </Div>

        <ButtonLink
          label={messages.label_resend_code}
          disabled={isLoading || !isResendAvailable}
          onClick={handleRequestOtpOnEmail}
        />
      </Div>

      <Div
        width={1}
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row", "row"]}
        alignItems="center"
        justifyContent="center"
        gridGap={3}
      >
        <PrimaryButtonOutlined
          width={[1, 1, "150px", "150px"]}
          height={40}
          label={messages.label_back}
          onClick={onPrevStep}
        />
        <PrimaryButton
          width={[1, 1, "150px", "150px"]}
          height={40}
          label={messages.label_confirm}
          disabled={isLoading || !formik.dirty || !formik.isValid}
          onClick={formik.handleSubmit}
        />
      </Div>
    </>
  );
};

export default CandidateLoginOtpStep;
