import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTES, SSNNUMBER } from "@utils/constant";
import {
  getPinWithFullYear,
  ValidateSwedishSSN,
  validateSSNForAdultPerson,
} from "@utils/utils";

export const useSearch = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { search } = useLocation();
  const { customer: { id: customerId = "" } = {} } = useSelector(
    state => state.authReducer.userInfo
  );

  const [ssnNumber, setSsnNumber] = useState("");
  const [ssnError, setSsnError] = useState("");

  const handleOpenWizardDialog = useCallback(
    params => {
      const query = new URLSearchParams(params);

      history.push({
        pathname: ROUTES.WIZARD_SEARCH.URL,
        search: query.toString(),
      });
    },
    [history]
  );

  useEffect(() => {
    const query = new URLSearchParams(search);
    const value = query.get(SSNNUMBER);

    if (!value) {
      return;
    }

    handleOpenWizardDialog(query);
  }, [handleOpenWizardDialog, search]);

  const handleSearch = useCallback(() => {
    const ssnWithFullYear =
      ssnNumber.length < 12 ? getPinWithFullYear(ssnNumber) : ssnNumber;
    const isValidSSN = ValidateSwedishSSN(ssnWithFullYear);

    if (!isValidSSN) {
      setSsnError(messages.error_invalid_id);

      return;
    }

    const isAdult = validateSSNForAdultPerson(ssnWithFullYear, customerId);

    if (!isAdult) {
      setSsnError(messages.error_person_age_under_18);

      return;
    }

    handleOpenWizardDialog({ [SSNNUMBER]: ssnWithFullYear });
  }, [
    customerId,
    handleOpenWizardDialog,
    messages.error_invalid_id,
    messages.error_person_age_under_18,
    ssnNumber,
  ]);

  return { handleSearch, setSsnNumber, ssnError };
};
