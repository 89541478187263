import Axios from "@app/api/axios";

import { ORDER_CUSTOMER_FORM } from "@utils/constant";

const getFormDetails = customerFormId =>
  Axios.get(`${ORDER_CUSTOMER_FORM}/${customerFormId}`).then(
    response => response
  );

export default getFormDetails;
