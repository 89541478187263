import React from "react";
import PropTypes from "prop-types";

import Card from "@components/Card";
import { SurveyForm } from "@components/Survey";

import FormConfig from "./CoordinationNumberForm.config.json";
import { useCoordinationNumberForm } from "./CoordinationNumberForm.hooks";

const CoordinationNumberForm = ({ onSubmit }) => {
  const { handleLoadSurveyRef, handleSave } =
    useCoordinationNumberForm(onSubmit);

  return (
    <Card py="24px" px={3} width={1}>
      <SurveyForm
        formConfig={FormConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
      />
    </Card>
  );
};

CoordinationNumberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CoordinationNumberForm;
