import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import createNewProduct from "@app/services/services/createNewProduct";

import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES, SUBSCRIPTION_TYPE } from "@utils/constant";

import Form from "./Form";

const CreateSubscription = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: messages.title_create_subscription,
    },
  ];

  const handleCreateSubscription = async values => {
    const payload = {
      ...values,
      purchase_by_credits: true,
      price_credits: values.price,
      order_type: SUBSCRIPTION_TYPE,
    };

    try {
      await createNewProduct(payload);

      history.push(ROUTES.ADMIN_PRODUCTS_LIST.URL);
    } catch (e) {
      //catch error
    }
  };

  const headerConfig = {
    title: messages.title_create_subscription,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 12]} />
      <Div
        width={[1, 1, 1, 800]}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Form onSubmit={handleCreateSubscription} />
      </Div>
    </AdminContainer>
  );
};

export default CreateSubscription;
