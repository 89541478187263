import React from "react";

import TabsNavigator from "@components/TabsNavigator";

import AdminContainer from "@layout/AdminContainer";

import { useAdminCasesList } from "./AdminCasesList.hooks";

const AdminCasesList = () => {
  const { headerConfig, tabContent, handleActiveIndexChange } =
    useAdminCasesList();

  return (
    <AdminContainer config={headerConfig} wide>
      <TabsNavigator
        content={tabContent}
        onActiveIndexChange={handleActiveIndexChange}
      />
    </AdminContainer>
  );
};
export default AdminCasesList;
