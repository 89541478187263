import { ORDER_TYPE } from "@utils/enum";

const processPayloadWithIndividualSSN = (item = {}) => {
  return item.services.map(({ id }) => ({
    service_id: id,
    personal_number: item.ssn,
    person_name: item.name,
    phone: item.phone,
    zip_code: item.zipcode?.replace(/\s/g, ""),
    person_first_name: item.firstName,
    person_last_name: item.lastName,
    city: item.city,
    street_address: item.street,
  }));
};

const processPayloadWithoutSSN = (item = {}) => {
  return item.services.map(({ id }) => {
    const payload = {
      service_id: id,
      person_first_name: item.person_first_name,
      person_last_name: item.person_last_name,
      date_of_birth: item.date_of_birth,
    };

    return payload;
  });
};

const processPayloadForCompany = (item = {}) => {
  return item.services.map(({ id }) => ({
    service_id: id,
    company_name: item.company_name,
    organisation_number: item.organisation_number,
  }));
};

const processPayloadWithCoordinationNumber = (item = {}) => {
  return item.services.map(({ id }) => ({
    service_id: id,
    coordination_number: parseInt(item.coordination_number?.replace("-", "")),
    person_first_name: item.person_first_name,
    person_last_name: item.person_last_name,
  }));
};

const processPayloadWithSSNNotAvailable = (item = {}) => {
  return item.services.map(({ id }) => ({
    service_id: id,
    personal_number: parseInt(item.ssn?.replace("-", "")),
    person_first_name: item.person_first_name,
    person_last_name: item.person_last_name,
  }));
};

export const processPayloadByOrderType = (personInfo = []) => {
  const PayloadProcessorsByType = {
    [ORDER_TYPE.INDIVIDUAL_SSN]: processPayloadWithIndividualSSN,
    [ORDER_TYPE.INDIVIDUAL_WITHOUT_SSN]: processPayloadWithoutSSN,
    [ORDER_TYPE.COMPANY]: processPayloadForCompany,
    [ORDER_TYPE.INDIVIDUAL_COORDINATION_NUMBER]:
      processPayloadWithCoordinationNumber,
    [ORDER_TYPE.INDIVIDUAL_SSN_NOT_AVAILABLE]:
      processPayloadWithSSNNotAvailable,
  };

  const orderItems = personInfo.flatMap(infoItem => {
    const payloadProcessor = PayloadProcessorsByType[infoItem.order_type];

    if (!payloadProcessor) {
      return infoItem;
    }

    return payloadProcessor(infoItem);
  });
  const [{ order_type: orderType } = {}] = personInfo;

  return {
    orderItems,
    orderType,
  };
};
