import React from "react";
import { FormattedMessage } from "react-intl";

import { ROUTES } from "@utils/constant";

export const OTHER_OPTIONS = [
  [
    {
      iconName: "user-switch",
      title: (
        <FormattedMessage id="title_individual_with_coordination_number" />
      ),
      description: (
        <FormattedMessage id="description_individual_with_coordination_number" />
      ),
      label: <FormattedMessage id="label_send" />,
      url: ROUTES.ORDER_COORDINATION_NUMBER_DETAILS.URL,
    },
    {
      iconName: "check-shield",
      title: <FormattedMessage id="title_individual_ssn_not_available" />,
      description: (
        <FormattedMessage id="description_individual_ssn_not_available" />
      ),
      label: <FormattedMessage id="label_send" />,
      url: ROUTES.ORDER_SSN_NOT_AVAILABLE_DETAILS.URL,
    },
    {
      iconName: "AssignmentsA",
      title: <FormattedMessage id="title_no_ssn" />,
      description: <FormattedMessage id="description_no_ssn" />,
      label: <FormattedMessage id="label_send" />,
      url: ROUTES.ORDER_WITH_NO_SSN_DETAILS.URL,
    },
  ],
  [
    {
      iconName: "usersA",
      title: <FormattedMessage id="title_batch_order" />,
      description: <FormattedMessage id="description_batch_order" />,
      label: <FormattedMessage id="label_send" />,
      url: "",
      isHidden: true,
    },
    {
      iconName: "organisation",
      title: <FormattedMessage id="title_organisation_check" />,
      description: <FormattedMessage id="description_organisation_check" />,
      label: <FormattedMessage id="label_send" />,
      url: ROUTES.ORDER_COMPANY_DETAILS.URL,
    },
  ],
];
