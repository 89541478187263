import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import userService from "@app/services/users/userService";

import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";

import { ROUTES, ORDERS_API } from "@utils/constant";
import { ORDER_TYPE } from "@utils/enum";

const searchableColumns = ["id", "invoice_id"];

const Orders = ({ customerId, billingAccountId }) => {
  const { messages } = useIntl();
  const [userData, setUserData] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const users = await userService(
          `customer_id=${customerId}&per_page=100`
        );
        const { data: { data: userData = [] } = {} } = users;
        await setUserData(
          userData.map(o => ({ name: `${o?.name} #${o?.id}`, code: o?.id }))
        );
        setIsFilterValuesLoaded(true);
      } catch (e) {
        //catch error
      }
    };

    loadServices();
  }, [customerId]);

  const invoiceStatusTranslation = {
    "Not invoiced": messages.label_not_invoiced,
    Invoicing: messages.label_invoiced,
    Cancelled: messages.label_cancelled,
  };

  const orderTypeFilterOptions = useMemo(
    () =>
      Object.values(ORDER_TYPE).map(value => ({
        name: messages[value],
        code: value,
      })),
    [messages]
  );

  const config = {
    header: {
      title: "title_orders",
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        label: messages.label_date,
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.filter_date_from,
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
            value: "",
          },
        ],
      },
      {
        title: messages.label_invoice_status,
        id: "filter[invoice_status]",
        type: "checkbox",
        options: [
          {
            label: messages.label_not_invoiced,
            value: "Not invoiced",
            checked: false,
          },
          {
            label: messages.label_invoiced,
            value: "Invoicing",
            checked: false,
          },
          {
            label: messages.label_cancelled,
            value: "Cancelled",
            checked: false,
          },
        ],
      },
      {
        title: messages.investigator,
        id: "filter[user_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[user_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[user_id]",
            options: userData,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_order_type,
        id: "filter[type]",
        type: "multiselect",
        options: [
          {
            id: "filter[type]",
            name: "filter[type]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: orderTypeFilterOptions,
          },
        ],
      },
    ],
    enable_csv_download: true,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_customer_orders_no_objects_found",
    enable_search: true,
    csv_filename: "orders.csv",
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "created_at",
            "total_price",
            "customer_id",
            "user_id",
            "invoice_status",
            "invoice_id",
          ],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[customer_id]", customerId],
        ["filter[billing_account_id]", billingAccountId],
      ],
    },
    is_filter_options_updated: isFilterValuesLoaded,
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "id",
        type: "id",
        className: "text-blue",
        width: "100px",
        href: values => {
          const { id } = values;
          return `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${id}`;
        },
        sortable: true,
        sortField: "id",
      },
      {
        title: "label_date",
        db_field: "created_at",
        sortable: true,
        type: "dateTime",
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "link",
        href: values => {
          const { id, customer_id } = values;
          return `/admin/kunder/${customer_id}/faktura/${id}/detaljer`;
        },
      },
      {
        title: "label_service",
        db_field: "order_items",
        type: "text",
        formatter: value => {
          const totalOrderItems = value?.length;
          return totalOrderItems > 1
            ? `${value[0]?.name} + ${totalOrderItems - 1}`
            : value[0]?.name;
        },
      },
      {
        title: "watchlist_label_price",
        db_field: "total_price",
        type: "currency",
        width: "100px",
      },
      {
        title: "label_invoice_status",
        db_field: "invoice_status",
        type: "text",
        formatter: value => {
          return invoiceStatusTranslation[value];
        },
        sortField: "invoice_status",
      },
      {
        title: "label_invoice_id",
        db_field: "invoice_id",
        type: "text",
        width: "100px",
        formatter: value => {
          return value || "-";
        },
        sortField: "invoice_id",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) => `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${id}`,
          },
        ],
      },
    ],
  };
  return (
    <Container m={"0px !important"}>
      <DataTable config={config} />
    </Container>
  );
};
Orders.propTypes = {
  customerId: PropTypes.string,
  billingAccountId: PropTypes.string,
};
export default Orders;
