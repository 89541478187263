import React from "react";
import PropTypes from "prop-types";

import Card from "@components/Card";
import { SurveyForm } from "@components/Survey";

import FormConfig from "./IndividualSSNNotAvailableForm.config.json";
import { useIndividualSSNNotAvailableForm } from "./IndividualSSNNotAvailableForm.utils";

const IndividualSSNNotAvailableForm = ({ onSubmit }) => {
  const { handleLoadSurveyRef, handleSave } =
    useIndividualSSNNotAvailableForm(onSubmit);

  return (
    <Card py="24px" px={3} width={1}>
      <SurveyForm
        formConfig={FormConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
      />
    </Card>
  );
};

IndividualSSNNotAvailableForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default IndividualSSNNotAvailableForm;
