import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { Error } from "@components/Heading";
import ComponentMultiSelect from "@components/MultiSelect"; // Assuming you have a MultiSelect component
import Icon from "@components/Icon";
import { useFormikContext } from "formik";

const MultiSelect = ({
  name,
  placeholder,
  options,
  selectedOption,
  optionLabel,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const handleChange = selectedOption => {
    setFieldValue(name, selectedOption.value);
  };

  const selectedValue =
    values[name] || (selectedOption ? selectedOption.value : undefined);

  return (
    <Div flex="1 1 40%">
      <ComponentMultiSelect
        width={1}
        mt={2}
        name={name}
        value={selectedValue}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        display="chip"
        dropdownIcon={<Icon name="chevrondown" />}
        optionLabel={optionLabel}
      />
      {errors[name] && touched[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors[name]}</Error>
        </Div>
      )}
    </Div>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  optionLabel: PropTypes.string,
};

export default MultiSelect;
