import React from "react";

import AvailableServices from "@pages/shared/AvailableServices";

import { ORDER_TYPE } from "@utils/enum";
import { AvailableServicesHeader } from "./components";

const AvailableServicesSSNNotAvailable = () => {
  return (
    <AvailableServices
      orderType={ORDER_TYPE.INDIVIDUAL_SSN_NOT_AVAILABLE}
      header={<AvailableServicesHeader />}
    />
  );
};

export default AvailableServicesSSNNotAvailable;
