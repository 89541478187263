import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";

import ProductConfigurationConfig from "./ProductConfigurationConfig.json";

import SurveyForm from "../../../../components/Survey/SurveyForm";

function ConfigurationEditFieldCard({ title, config, onHide, onSubmit }) {
  const [configurationData, setConfigurationData] = useState({});

  const surveyRef = useRef(null);

  useEffect(() => {
    const initialData = {
      service_type:
        config.find(value => value.key === "service_type")?.value || null,
      analysis_type:
        config.find(value => value.key === "analysis_type")?.value || null,
      process_type:
        config.find(value => value.key === "process_type")?.value || null,
      court_package:
        config.find(value => value.key === "court_package")?.value || null,
      add_on_products:
        config
          .find(value => value.key === "add_on_products")
          ?.value?.map(item => item?.id) || null,
      data_source:
        config
          .find(value => value.key === "data_source")
          ?.value?.map(item => item.code) || null,
      order_type:
        config
          .find(value => value.key === "order_type")
          ?.value?.map(item => item.code) || null,
      fortnox_article_number:
        config.find(value => value.key === "fortnox_article_number")?.value ||
        null,
      legal_framework:
        config
          .find(value => value.key === "legal_framework")
          ?.value?.map(item => item.code) || null,
      child:
        config
          .find(value => value.key === "child")
          ?.value?.map(item => {
            return item?.code;
          }) || null,
      is_case: config.find(value => value.key === "is_case")?.value ?? null,
      is_report: config.find(value => value.key === "is_report")?.value ?? null,
    };
    setConfigurationData(initialData);
  }, [config]);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    const updatedData = { ...data };

    Object.keys(configurationData).forEach(key => {
      if (!(key in data)) {
        updatedData[key] = null;
      }
    });

    onSubmit(updatedData);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, 1]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
      className="popup-card"
    >
      <H3 textAlign="center" pt="8px">
        {title}
      </H3>

      <SurveyForm
        data={configurationData}
        formConfig={ProductConfigurationConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
        onClose={onHide}
      />
    </Dialog>
  );
}

ConfigurationEditFieldCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfigurationEditFieldCard;
