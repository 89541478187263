import React from "react";

export const useCandidateForm = onSubmit => {
  const surveyRef = React.useRef(null);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { current } = surveyRef;

    if (!current) {
      return;
    }

    const { data } = current;

    onSubmit(data);
  };

  return {
    handleLoadSurveyRef,
    handleSave,
  };
};
