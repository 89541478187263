import React, { useEffect, useRef, useState } from "react";
import SurveyForm from "../../../../components/Survey/SurveyForm";
import SettingsConfig from "./SettingsConfig.json";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";
import PropTypes from "prop-types";

function SettingsEditFeildCard({ title, config, onHide, onSubmit }) {
  const surveyRef = useRef(null);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const initialData = config.reduce((acc, { key, value }) => {
      acc[key] = value?.toString() ?? null;
      return acc;
    }, {});

    setProductData(initialData);
  }, [config]);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    onSubmit(data);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, 1]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
      className="popup-card"
    >
      <H3 textAlign="center" pt="8px">
        {title}
      </H3>
      <SurveyForm
        data={productData}
        formConfig={SettingsConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
        onClose={onHide}
      />
    </Dialog>
  );
}

SettingsEditFeildCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SettingsEditFeildCard;
