import React from "react";
import { useDispatch } from "react-redux";

import { personInformationActions } from "@app/reducers/personInformationReducer";

import { useStepperNavigation } from "@contexts/StepperContext";

export const useIndividualSSNNotAvailable = () => {
  const dispatch = useDispatch();
  const { onNextStep } = useStepperNavigation();

  const handleSubmit = React.useCallback(
    data => {
      dispatch(personInformationActions.personInfo(data));

      onNextStep();
    },
    [dispatch, onNextStep]
  );

  return {
    handleSubmit,
  };
};
